import React, { useRef, useEffect } from 'react';
import './transition.css';
import { Power4 } from 'gsap';
import $ from 'jquery';
const Transition = ({ timeline }) => {
     const trans = useRef(null);
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          timeline.to(trans.current, {
               duration: 4,
               x: 2600,
               ease: Power4.easeOut,
          });
     }, []);

     return (
          <div>
               <div className="transition-effect" ref={trans}></div>
               <div className="loading">
                    <h3>Aguarde...</h3>
               </div>
          </div>
     );
};

export default Transition;
