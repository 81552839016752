import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/autoestima.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Autoestima = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Autoestima: o que é e como melhorar a sua
                    </h1>
                    <h3 className="date">
                         14 de janeiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p> Autoestima: o que é e como melhorar a sua</p>
                    </h3>
                    <div className="textos-intro">
                         <p className="m-15">
                              A <strong>autoestima</strong> é a capacidade que
                              temos de gostar de nós mesmos, de nos sentirmos
                              bem, felizes e confiantes mesmo quando nada está
                              bem ao nosso redor — porque sabemos o nosso valor.
                              Esse estado de <strong>“amar a si mesmo” </strong>
                              requer atitudes como o autorrespeito, a
                              autoaceitação e o{' '}
                              <strong>autoconhecimento</strong>. Saiba mais:
                         </p>
                    </div>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>

                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <h2 className="subtitle">
                              Mas afinal, o que é autoestima?
                         </h2>
                         <p className="m-15">
                              Basicamente, é a autoestima que determina a
                              maneira como as pessoas se relacionam com o mundo,
                              encaram os desafios da rotina diária e se protegem
                              ou se expõem em situações que exigem{' '}
                              <strong>controle emocional</strong>.
                         </p>
                         <p className="m-15">
                              No entanto, o grande problema é que o número de
                              pessoas com baixa autoestima só aumenta, o que
                              pode trazer efeitos negativos, aumentando o risco
                              de doenças como
                              <strong> ansiedade e depressão</strong>.
                         </p>
                         <p className="m-15">
                              A boa notícia é que é possível restaurar esse
                              relacionamento com você e ser feliz e positivo.
                              Pode ser uma tarefa desafiadora e demorada, que
                              talvez exija ajuda profissional, dependendo de
                              cada caso.
                         </p>
                         <p className="m-15">
                              É normal se julgar em alguns momentos, a começar
                              pelo <strong>corpo</strong>. Principalmente as
                              mulheres, que ainda sofrem com a pressão imposta
                              por <strong>padrões de beleza</strong> que sempre
                              existiram e mudam de acordo com a época que
                              vivemos.
                         </p>
                         <p className="m-15">
                              “O sentimento de frustração é o principal vilão da
                              autoestima. Isso porque a pessoa não se reconhece
                              como merecedora de coisas boas, tampouco enxerga
                              suas qualidades, nem se valoriza como o ser humano
                              que é”, pontua Silvia Donati, coach e leader coach
                              pela Sociedade Brasileira de Coaching.
                         </p>

                         <h2 className="subtitle">Pilares da autoestima</h2>
                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>Autoaceitação:</strong> Ou seja,
                                   estar de acordo com o que você é e com o seu
                                   corpo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>Autoconfiança:</strong> Isto é, ter a
                                   convicção de que você é capaz de alcançar
                                   algo ou de superar algum problema;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>Habilidade social:</strong> Por fim,
                                   viver experiências sociais com equuilíbrio.
                              </li>
                         </ul>
                         <h2 className="subtitle">
                              Como identificar que minha autoestima é baixa?
                         </h2>
                         <p className="m-15">
                              Segundo Donati, há ainda outras formas de detectar
                              a baixa autoestima. Algumas delas:
                         </p>
                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>
                                        Você projeta a sua felicidade em fatores
                                        externos:
                                   </strong>{' '}
                                   Em outras palavras, você só fica feliz quando
                                   compra algo novo, viaja, conquista uma
                                   promoção ou ganha dinheiro. “A autoestima não
                                   depende de acontecimentos para existir. É um
                                   estado de felicidade e equilíbrio interno”,
                                   esclarece a coach.
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>
                                        Há a necessidade de reconhecimento e de
                                        aprovação de terceiros:
                                   </strong>{' '}
                                   Se não houver alguém elogiando seu trabalho
                                   ou sua atitude, haverá frustração e
                                   sentimentos de inferioridade rondando os
                                   pensamentos;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>
                                        O autojulgamento é tão constante que
                                        atrapalha até mesmo o relacionamento com
                                        outras pessoas:{' '}
                                   </strong>{' '}
                                   Por exemplo, é difícil aceitar um elogio de
                                   alguém sobre sua aparência ou trabalho – há
                                   uma cobrança excessiva para ser bom em tudo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>
                                        Você está insatisfeito com a própria
                                        aparência, sempre buscando formas de
                                        mudar o visual:
                                   </strong>{' '}
                                   Porque a imagem atual nunca está boa o
                                   bastante para o indivíduo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   <strong>
                                        Você se compara com os outros, criando
                                        uma distorção de imagem de si próprio:
                                   </strong>{' '}
                                   Esse distúrbio está cada vez mais frequente
                                   por causa das <strong>redes sociais</strong>{' '}
                                   — influenciadores e celebridades postam sobre
                                   suas vidas e selfies “perfeitas”, o que
                                   aumenta a decepção por não ter aquele status
                                   e aquela aparência. Sabe o ditado “a grama do
                                   vizinho é sempre mais verde”? Pois é
                                   exatamente esse sentimento despertado com
                                   esse tipo de conteúdo virtual.
                              </li>
                         </ul>
                         <h2 className="subtitle">
                              Riscos para a saúde da autoestima baixa
                         </h2>

                         <p className="m-15">
                              Autoestima baixa é coisa séria, que pode levar a
                              transtornos nocivos para a saúde mental, como
                              depressão, ansiedade,{' '}
                              <strong>síndrome do pânico</strong>, bulimia,
                              anorexia e outros distúrbios. Ou seja, o que
                              parece inofensivo, pode virar doença e situações
                              mais extremas – automutilação e até suicídio.
                         </p>
                         <h2 className="subtitle">
                              Como melhorar a sua autoestima
                         </h2>
                         <p className="m-15">
                              <strong>
                                   Cerque-se de pessoas que ajudem você a
                                   enxergar e resgatar seus valores
                              </strong>
                         </p>
                         <p className="m-15">
                              Inclusive com auxílio profissional.{' '}
                              <strong>Fazer terapia</strong> é uma forma de
                              mergulhar em suas camadas mais profundas e de
                              descobrir a origem de suas inseguranças.
                         </p>
                         <p className="m-15">
                              <strong>
                                   Faça uma lista com todas as qualidades e as
                                   conquistas que você possui
                              </strong>
                         </p>
                         <p className="m-15">
                              Além disso, deixe-a em um local visível para
                              sempre se lembrar.
                         </p>
                         <p className="m-15">
                              <strong>
                                   {' '}
                                   Inspire-se e se espelhe em pessoas que são
                                   exemplo de autoestima elevada
                              </strong>
                         </p>
                         <p className="m-15">
                              Que se amam como são, independentemente de{' '}
                              <strong>peso</strong>, forma física e situação de
                              vida. Lembre-se de que a satisfação deve ser de
                              dentro para fora, mas ter ídolos que impulsionem
                              esse processo pode ajudar.
                         </p>
                         <p className="m-15">
                              <strong>Crie uma rotina de autocuidado</strong>
                         </p>
                         <p className="m-15">
                              Com atividades que façam você se sentir bem.
                              Experimente dar uma caminhada depois do trabalho,
                              praticar <strong>yoga, meditar</strong>, fazer uma
                              refeição saborosa. Tudo é válido para se sentir
                              bem e se curtir.
                         </p>
                         <p className="m-15">
                              <strong>
                                   Corte relacionamentos tóxicos de sua vida
                              </strong>
                         </p>
                         <p className="m-15">
                              Pessoas que reclamam demais, que julgam ou que
                              fazem de tudo para que você se sinta
                              desconfortável ou diminuído.
                         </p>
                         <p className="m-15">
                              <strong>Se valorize!</strong>
                         </p>
                         <p className="m-15">
                              Mesmo que você não se sinta satisfeito com sua
                              imagem ou com algo em sua rotina, tudo bem.{' '}
                              <strong> Valorize seus pontos fortes</strong> e
                              crie estratégias para mudar o que não está bom.
                         </p>
                         <p className="m-15">
                              <strong>Respeite seus altos e baixos</strong>
                         </p>
                         <p className="m-15">
                              Mesmo com a autoestima elevada, é normal ter dias
                              ruins e se sentir mal. Aprenda a diferenciar
                              momentos de estados permanentes para não alimentar
                              sentimentos negativos.
                         </p>
                         <p className="m-15">
                              <strong>Busque autoconhecimento</strong>
                         </p>
                         <p className="m-15">
                              Faça um curso, retiro ou qualquer vivência que
                              alimente a mente com informações e experiências
                              enriquecedoras. Às vezes, até um bate-papo com
                              amigos pode ser uma oportunidade de recuperar a
                              autoestima perdida.
                         </p>

                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Autoestima;
