import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/ciumes.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Ciumes = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Como controlar os ciúmes: 13 dicas de psicólogos
                    </h1>
                    <h3 className="date">
                         15 de janeiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>Como controlar os ciúmes: 13 dicas de psicólogos</p>
                    </h3>

                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>

                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <p className="m-15">
                              Como controlar os ciúmes no relacionamento é
                              sempre uma questão polêmica. Independente da
                              idade, é comum casais se depararem com esse
                              sentimento polêmico.
                         </p>
                         <p className="m-15">
                              Alguns fatores presentes na sociedade tornam
                              difícil combater os ciúmes. Por exemplo,
                              demonstrações do sentimento{' '}
                              <strong>
                                   ainda costumam ser vistas como gestos
                                   românticos
                              </strong>{' '}
                              quando, na verdade, existem maneiras mais
                              saudáveis de expressar o amor.
                         </p>
                         <p className="m-15">
                              O ciúme é, na verdade, um sinal de insegurança.{' '}
                         </p>
                         <p className="m-15">
                              A pessoa extremamente ciumenta ou não confia o
                              suficiente no parceiro ou tem{' '}
                              <strong>autoestima baixa</strong>. Ela procura{' '}
                              <strong>sabotar</strong> qualquer possibilidade do
                              parceiro encontrar outra pessoa porque, na cabeça
                              dela, indivíduos solteiros são ameaça.
                         </p>
                         <p className="m-15">
                              Esta conduta controladora gera brigas e discussões
                              frequentes, desgastando a relação. Além disso, o
                              ciúme exagerado é danoso para o indivíduo que o
                              sente. Esse sentimento estimula a{' '}
                              <strong>ansiedade</strong>, a desconfiança e a{' '}
                              <strong>raiva</strong>.
                         </p>
                         <h2 className="subtitle">Afinal, o que é o ciúme?</h2>
                         <p className="m-15">
                              Segundo a Psicóloga{' '}
                              <strong>Letícia Medeiros</strong>, ciúme é um
                              sentimento bem difícil de controlar e que aparece
                              por diversas razões. Pode ser ciúme do namorado,
                              de um amigo ou até do irmão.⠀
                         </p>
                         <p className="m-15">
                              Apesar de bem desagradável, ele é um sentimento
                              natural do ser humano, só que, dependendo do caso,
                              pode estar em um nível exagerado, causando um
                              ciúme patológico, que requer a ajuda de um
                              psicólogo.⠀
                         </p>
                         <p className="m-15">
                              O ciúme surge como um sinal de alerta, quando
                              acreditamos que algo não está indo bem ou como
                              desejamos.⠀
                         </p>
                         <p className="m-15">
                              Pode ser um problema real ou estar presente apenas
                              no imaginário. Só que faz parte do nosso instinto
                              querer eliminar toda e qualquer ameaça que nos
                              tornam inseguros, desprotegidos ou em
                              desvantagem.⠀
                         </p>
                         <h2 className="subtitle">
                              Dicas de como controlar os ciúmes
                         </h2>
                         <p className="m-15">
                              Primeiramente, reflita sobre os fatores que
                              costumam despertar os ciúmes.{' '}
                         </p>
                         <p className="m-15">
                              Se você fica enciumado quando o seu parceiro
                              interage com outras pessoas ou atende compromissos
                              sociais sem você, é provável que sofra de ciúme
                              excessivo.
                         </p>
                         <p className="m-15">
                              O caso é especialmente complicado{' '}
                              <strong>
                                   {' '}
                                   quando você sente ciúmes de amigos e
                                   familiares
                              </strong>
                              , com quem o seu companheiro convive quase
                              diariamente.{' '}
                         </p>
                         <p className="m-15">
                              Após fazer esse exercício, será mais fácil
                              identificar quais aspectos do seu comportamento
                              devem ser trabalhados. Em seguida, relacione-os
                              com as nossas dicas de como controlar os ciúmes!
                         </p>
                         <h2 className="subtitle2">
                              1. Analise a razão dos ciúmes
                         </h2>
                         <p className="m-15">
                              Questione-se sobre o motivo dos ciúmes. Qual é a
                              situação que despertou o sentimento? Por que você
                              está com ciúmes? Aconteceu algo no passado para
                              você se sentir assim hoje? Se houver,{' '}
                              <strong>
                                   é uma questão que deve ser conversada
                              </strong>{' '}
                              com seu companheiro.{' '}
                         </p>
                         <p className="m-15">
                              A <strong>psicóloga Letícia Medeiros</strong>
                              afirma ainda que os ciúmes mais comuns são os que
                              ocorrem no âmbito dos relacionamentos amorosos.
                         </p>
                         <p className="m-15">
                              Nestes casos, o desejo exagerado de{' '}
                              <strong>posse</strong> e de exigência egocêntrica
                              próprios de todas as formas de ciúmes é somado à
                              exigência de uma fidelidade mais ou menos acordada
                              e à desmoralização social que pode surgir a partir
                              da infidelidade.⠀
                         </p>
                         <p className="m-15">
                              Esta situação de desconfiança contínua gera uma
                              grande tensão emocional na pessoa ciumenta e em
                              seu parceiro. Este último se sente assediado,
                              monitorado e interrogado na maior parte do tempo,
                              geralmente sem motivos.⠀
                         </p>
                         <p className="m-15">
                              Se não houver consciência de que o ciúmes pode
                              estar em excesso, é pouco provável que a pessoa vá
                              buscar ajuda. ⠀
                         </p>
                         <p className="m-15">
                              O mais provável é que por trás disso existam
                              pensamentos distorcidos acerca do relacionamento
                              amoroso e do comportamento da pessoa amada.
                         </p>
                         <p className="m-15">
                              Uma vez recolhida esta informação, é preciso
                              analisá-la. Temos que buscar provas de que o que
                              pensamos é real ou está realmente acontecendo.⠀
                         </p>
                         <p className="m-15">
                              Por mais que as pessoas ciumentas tenham uma má
                              fama, a verdade é que elas sofrem e que seu
                              sofrimento é real. Por isso, se este problema
                              condiciona a sua vida em algum grau, não se
                              envergonhe e procure ajuda para controlar os
                              ciúmes.⠀
                         </p>
                         <h2 className="subtitle2">
                              2. Deixe as suas intenções claras
                         </h2>
                         <p className="m-15">
                              Logo no início do relacionamento já{' '}
                              <strong>deixe as suas intenções claras</strong>.
                              Se elas se modificaram ao longo da relação,
                              comunique-as ao parceiro para que não haja
                              desentendimentos no futuro.{' '}
                         </p>
                         <h2 className="subtitle2">
                              3. Trabalhe a confiança no relacionamento
                         </h2>
                         <p className="m-15">Você confia no seu parceiro?</p>
                         <p className="m-15">
                              Se não conseguir responder ‘sim’, o melhor a fazer
                              é conversar sobre isso. Explane sobre os
                              comportamentos do parceiro que causam
                              desconfiança,{' '}
                              <strong>
                                   mas tenha em mente que não é possível mudar
                                   as pessoas.
                              </strong>
                         </p>
                         <p className="m-15">
                              Tudo o que você pode fazer é expressar os seus
                              sentimentos e observar a reação do seu parceiro.{' '}
                         </p>
                         <p className="m-15">
                              O ciúme é um sentimento natural em todo ser capaz
                              de criar vínculos afetivos e funciona como um
                              alarme interno, que pode ser acionado por motivos
                              concretos ou abstratos, imaginários.
                         </p>
                         <p className="m-15">
                              Ele está relacionado com o medo de perder a
                              atenção de alguém ou até mesmo perder esse alguém
                              para outro.
                         </p>
                         <p className="m-15">
                              Antes de mais nada, reconheça quando você está
                              sendo ciumento, credite que você merece que alguém
                              te ame e que quer ficar só com você. Além disso,
                              aprenda a confiar no outro.
                         </p>
                         <h2 className="subtitle2">
                              4. Mantenha o diálogo em dia
                         </h2>
                         <p className="m-15">
                              Esta dica é um complemento das duas anteriores.
                              Além de deixar bem claro o que você procura no
                              relacionamento, converse sempre que se sentir
                              inseguro, após uma discussão, e também compartilhe
                              as coisas boas.
                         </p>
                         <p className="m-15">
                              Você precisa sentir que pode se abrir com ele/ela.
                              Caso contrário,{' '}
                              <strong>
                                   o desconforto em dialogar pode indicar um
                                   ponto a ser trabalhado.
                              </strong>{' '}
                         </p>
                         <h2 className="subtitle2">
                              5. Eleve a sua autoestima
                         </h2>
                         <p className="m-15">
                              Quando a <strong>autoestima</strong> é forte, é
                              impossível acabar com a sua segurança. Além disso,{' '}
                              <strong>
                                   pessoas com autoestima elevada confiam em si
                                   mesmas
                              </strong>
                              . Conseguem distinguir quais condutas merecem
                              atenção e quais devem ser ignoradas, pois sabem o
                              que é melhor para elas.{' '}
                         </p>
                         <p className="m-15">
                              Embora relacionar-se seja saudável e parte da
                              vida, não se pode esquecer quem merece toda a sua
                              atenção: você mesmo. Por isso, trabalhe a sua
                              autoestima para que você possa amar também a
                              pessoa refletida no espelho.{' '}
                         </p>
                         <h2 className="subtitle2">
                              6. Combata o comportamento controlador
                         </h2>
                         <p className="m-15">
                              É impossível controlar a liberdade do outro.{' '}
                         </p>
                         <p className="m-15">
                              As pessoas são diferentes, portanto, vivem, pensam
                              e agem de forma diferente. Quando você não
                              respeita isso,
                              <strong>
                                   {' '}
                                   demonstra que não confia totalmente no seu
                                   parceiro.
                              </strong>
                         </p>
                         <h2 className="subtitle2">7. Não viva no passado</h2>
                         <p className="m-15">
                              Se você já sofreu uma <strong>traição</strong>, é
                              normal ficar com alguns receios. No entanto, em
                              algum momento, precisará se libertar do passado.
                         </p>
                         <p className="m-15">
                              <strong>
                                   Não traga as inseguranças oriundas de outro
                                   relacionamento para a relação atual
                              </strong>
                              . Se não conseguiu superar o trauma e sente que é
                              uma constante negativa em sua vida, já pensou em
                              procurar ajuda profissional para curar a ferida?{' '}
                         </p>
                         <h2 className="subtitle2">
                              8. Racionalize o sentimento
                         </h2>
                         <p className="m-15">
                              Quando a crise aparecer, veja os ciúmes com os
                              olhos de um cientista. Faz sentido não querer que
                              o parceiro encontre os amigos? Todo mundo sai para
                              se divertir com quem ama e confia, então, qual é o
                              problema?{' '}
                              <strong>
                                   Esta dica é especialmente eficaz para quem
                                   sofre com crises recorrentes.
                              </strong>{' '}
                         </p>
                         <h2 className="subtitle2">
                              9. Aprenda a demonstrar o amor de forma diferente
                         </h2>
                         <p className="m-15">
                              Os ciúmes, ao contrário do que muitos pensam, não
                              são uma expressão de amor.{' '}
                              <strong>São uma forma de possessão</strong>.
                         </p>
                         <p className="m-15">
                              Você provavelmente já se deparou com histórias de
                              casais que brigaram em um local público por causa
                              de ciúmes. Esta é a verdadeira face dos ciúmes,
                              desagradável e egocêntrica. Em contraste, o amor é
                              manso, gostoso e leve.
                         </p>
                         <h2 className="subtitle2">
                              10. Não se compare com os ex-companheiros
                         </h2>
                         <p className="m-15">
                              Assim como você não deve cair em devaneios sobre
                              relacionamentos passados, não precisa se comparar
                              com os ex-parceiros da outra pessoa.{' '}
                              <strong>Afinal, cada um é cada um!</strong>
                         </p>
                         <p className="m-15">
                              Se o seu parceiro está com você até hoje e lhe
                              trata super bem, é sinal que escolheu ficar com
                              você porque gosta da sua personalidade.{' '}
                         </p>

                         <h2 className="subtitle2">
                              11. Mantenha uma vida social ativa
                         </h2>
                         <p className="m-15">
                              Muitas pessoas se envolvem tanto com a relação que
                              negligenciam amigos e familiares assim como deixam
                              de fazer o que amam para viver em função de
                              agradar o parceiro.{' '}
                              <strong>
                                   Essa postura nociva pode se tornar tóxica.
                              </strong>{' '}
                              Todo mundo precisa de uma vida social ativa.
                         </p>
                         <h2 className="subtitle2">
                              12. Fortaleça o seu amor próprio
                         </h2>
                         <p className="m-15">
                              Mais uma vez,
                              <strong>
                                   {' '}
                                   reforça-se a importância de amar a si mesmo
                                   acima de tudo
                              </strong>
                              . Não se trata de narcisismo ou egocentrismo, mas,
                              sim, de respeito consigo mesmo. Lembre-se todos os
                              dias de como você é importante para este mundo e
                              não permita que ninguém lhe diga o contrário.{' '}
                         </p>
                         <h2 className="subtitle2">13. Busque terapia</h2>

                         <p className="m-15">
                              Segundo a psicóloga{' '}
                              <strong>Letícia Medeiros</strong>, Freud dividia o
                              ciúme em três níveis: competitivo/normal, o
                              projetado e o delirante.
                         </p>
                         <p className="m-15">
                              Dessa forma, cada nível possui um grau diferente
                              de intensidade e de mecanismos emocionais
                              envolvidos, podendo inclusive ter um caráter
                              doentio nas condições mais graves.
                         </p>
                         <p className="m-15">
                              Para Freud, o ciúme é composto, principalmente,
                              pelo pesar e sofrimento causado pelo pensamento de
                              perder o objeto amado, e da ferida narcísica, de
                              sentimentos de inimizade contra o rival
                              bem-sucedido e de maior ou menor quantidade de
                              autocrítica.
                         </p>
                         <p className="m-15">
                              Nesse sentido, há vários sofrimentos implicados,
                              como por exemplo: a perda do objeto amado, que
                              gera a dor do luto. Há também a dor narcísica, o
                              defrontar-se com a ideia de que não se é tão
                              indispensável para o amado como se pensava (filhos
                              mais velhos, por exemplo, com a chegada do irmão
                              mais novo, é bastante comum!).
                         </p>
                         <p className="m-15">
                              Para Freud, não há quem já não o tenha sentido
                              ciúmes!
                         </p>
                         <p className="m-15">
                              O ciúme se torna patológico quando o indivíduo
                              elabora seus conflitos. A psicoterapia é uma
                              ferramenta que auxilia no processo de elaboração
                              dos conflitos internos que impactam nossas formas
                              de relacionar!
                         </p>
                         <p className="m-15">
                              A <strong>terapia de casal </strong>pode ser uma
                              alternativa para relacionamentos sufocados pelo
                              ciúmes. Conforme o sentimento aumenta, pode ser
                              difícil extingui-lo totalmente.
                         </p>
                         <p className="m-15">
                              Da mesma forma, a <strong>terapia</strong> pode
                              ajudar você, como indivíduo, a encontrar a{' '}
                              <strong>autoconfiança</strong> necessária para,
                              enfim, aprender como controlar os ciúmes.
                         </p>
                         <p className="m-15">
                              Raízes Psicologia está aqui para ajudar a
                              libertá-lo dos ciúmes! Conte conosco para tratar
                              essa questão e, ainda, devolver o amor leve e
                              gostoso ao seu relacionamento.
                         </p>

                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Ciumes;
