import React, { useEffect } from 'react';
import './preload.css';
import $ from 'jquery';
import { default as logo } from '../../assets/images/logo.webp';

const Preload = (display = false) => {
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
     }, []);
     useEffect(() => {
          document.querySelector('html').style.overflow = 'hidden';
          setTimeout(() => {
               document.querySelector('.preload').classList.add('sair');
          }, 4700);
          setTimeout(() => {
               document.querySelector('html').style.overflow = 'auto';
               document.querySelector('.preload').style.display = 'none';
         
          }, 5600);
     }, []);
     return (
          <div className="preload">
               <img src={logo} width="250" alt="logo"/>
          </div>
     );
};

export default Preload;
