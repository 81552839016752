import React from 'react';
import $ from 'jquery';
import './scrollTop.css';
import { FiChevronsUp } from 'react-icons/fi';
const ScrollTop = () => {
     $(document).ready(function () {
          const scroll = document.querySelector('.scrollTop');
          $(window).scroll(function () {
               if ($(this).scrollTop() > 300) {
                    scroll.style.display = 'flex';
               } else {
                    scroll.style.display = 'none';
               }

               if (document.querySelector('#artigo')) {
      
                document.querySelector('.circle-wpp').style.display = 'none';
                document.querySelector('.scrollTop').style.display = 'none';
           }
          });
     });
     return (
          <div
               className="scrollTop"
               onClick={() => {
                    $('html, body').animate(
                         {
                              scrollTop: 0,
                         },
                         1000
                    );
               }}
          >
               <FiChevronsUp size={40} color="#fff" className="arrow" />
          </div>
     );
};

export default ScrollTop;
