import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/3.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const AmorProprio = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Como ter mais amor-próprio: 4 dicas incríveis
                    </h1>
                    <h3 className="date">
                         15 de janeiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>Como ter mais amor-próprio: 4 dicas incríveis</p>
                    </h3>
                    <div className="textos-intro">
                         <p className="m-15">
                              O <strong>amor-próprio</strong> é essencial em
                              várias esferas de nossas vidas, seja nos
                              relacionamentos amorosos, nas amizades ou no
                              trabalho. Esse sentimento faz com que você se
                              valorize mais, sem deixar que outras pessoas
                              aproveitem da sua boa vontade. Mas, infelizmente,
                              nem todos sabem desenvolver o amor-próprio e, com
                              isso, se sentem menosprezados.
                         </p>
                         <p className="m-15">
                              Esse tipo de situação prejudica a nossa saúde
                              emocional, abrindo portas para o surgimento de
                              outras doenças mais sérias, como a depressão e a
                              <Link to="/blog/transtornos/ansiedade">
                                   {' '}
                                   <strong>
                                        <u>ansiedade</u>
                                   </strong>
                              </Link>
                              . Para ajudar você a se livrar desse mal,
                              escrevemos este artigo com 4 dicas incríveis de
                              como ter amor-próprio. Quer saber mais? Venha
                              conferir!
                         </p>
                    </div>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>

                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <h2 className="subtitle">
                              1. Não se preocupe em agradar as outras pessoas
                         </h2>
                         <p className="m-15">
                              Embora você faça de tudo para agradar uma pessoa,
                              nunca será o suficiente para fazê-la completamente
                              feliz. Por esse motivo, é fundamental que você não
                              se preocupe em agradar os outros sempre, pois,
                              muitas vezes, isso poderá lhe proporcionar muito
                              sofrimento, tendo em vista que não são todas as
                              pessoas que sabem reconhecer o esforço do próximo.
                         </p>
                         <p className="m-15">
                              Sendo assim, é muito importante que você ame a si
                              mesma e não espere nada de ninguém. Sempre que
                              você fizer isso e sentir uma paz interior, é sinal
                              de que está fazendo a coisa certa.
                         </p>
                         <h2 className="subtitle">
                              2. Tenha equilíbrio emocional
                         </h2>
                         <p className="m-15">
                              Ter <strong>equilíbrio emocional </strong>
                              significa que você precisa reconhecer os momentos
                              em que você não está bem. Para isso, é preciso que
                              você preste mais atenção a você mesma. Dessa
                              forma, será possível identificar quando está
                              começando a se sentir ansiosa e desenvolvendo
                              pensamentos negativos.
                         </p>
                         <p className="m-15">
                              Para resolver esse problema, é necessário que você
                              reconheça o momento difícil e coloque na sua mente
                              que esse tipo de pensamento não corresponde à
                              realidade.
                         </p>

                         <h2 className="subtitle">
                              3. Preserve a harmonia entre o corpo e a mente
                         </h2>
                         <p className="m-15">
                              Introduza hábitos saudáveis na sua rotina. Cuidar
                              de si é fundamental para{' '}
                              <strong>elevar a autoestima</strong>. Dessa forma,
                              é importante que você se alimente bem, dando
                              sempre preferência para verduras, legumes e frutas
                              em seu cardápio. Além disso, alinhar uma
                              alimentação adequada com a prática de atividades
                              físicas é fundamental para preservar a harmonia
                              entre o corpo e a mente. Lembre-se de que quanto
                              mais você se cuidar, mais você vai gostar de si
                              mesma.
                         </p>

                         <h2 className="subtitle">4. Aceite-se como você é</h2>
                         <p className="m-15">
                              Embora você veja aquelas fotos perfeitas de
                              influenciadores digitais e estrelas da TV nas
                              redes sociais, saiba que nem tudo ali corresponde
                              à realidade. Isso porque ninguém é perfeito. A
                              partir do momento em que você busca a perfeição, a
                              sua felicidade é deixada de lado.
                         </p>
                         <p className="m-15">
                              É lógico que precisamos dar o nosso melhor naquilo
                              que fazemos, mas isso não deve ser em função de
                              agradar os outros. O importante é sempre procurar
                              ser uma pessoa melhor, fazendo o bem para o seu
                              próximo, sem se preocupar com que os outros vão
                              pensar.
                         </p>
                         <p className="m-15">
                              O amor-próprio é fundamental para garantir a sua
                              felicidade. Lembre-se sempre de que você é uma
                              pessoa especial e linda, tanto por dentro quanto
                              por fora, e não deixe que comentários maldosos
                              interfiram na sua autoestima. Você vale muito mais
                              que isso!
                         </p>
                         <p className="m-15">
                              Gostou das nossas dicas sobre amor-próprio? Então
                              compartilhe este artigo nas redes sociais e ajude
                              seus amigos a aprenderem a se amar mais!
                         </p>

                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default AmorProprio;
