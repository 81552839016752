import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/depressao.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Depressao = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">Afinal, o que é depressão?</h1>
                    <h3 className="date">
                         22 de setembro de 2021 | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p> Afinal, o que é depressão?</p>
                    </h3>

                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>

                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <h2 className="subtitle marrom">
                              Afinal, o que é depressão? ﻿
                         </h2>
                         <p className="m-15">
                              A depressão é um transtorno mental, e é
                              extremamente comum. Apesar da abundância de
                              estudos sobre o tema e de ser tema recorrente na
                              mídia nos últimos tempos, ainda existe muito
                              estigma e desconhecimento sobre o tema.
                         </p>
                         <p className="m-15">
                              Em primeiro lugar, necessário reconhecer que a
                              depressão é um problema global de grande escala.
                              Ao redor do mundo{' '}
                              <strong>
                                   mais de 300 milhões de pessoas de todas as
                                   idades sofrem de depressão
                              </strong>{' '}
                              em maior ou menor grau. Aqui no Brasil, a crise é
                              potencialmente ainda mais séria –{' '}
                              <strong>
                                   nós somos o país com os mais altos índices de
                                   depressão da América Latina.
                              </strong>
                         </p>
                         <p className="m-15">
                              Além disso, ela é a principal causa de
                              incapacitação no mundo, e um fator de peso para a
                              carga geral de doenças ao redor do globo. Em suas
                              piores manifestações, a depressão pode levar à
                              automutilação e até ao suicídio.
                         </p>
                         <p className="m-15">
                              A preocupação com a o assunto é ainda maior dado o
                              fato de que{' '}
                              <strong>
                                   menos de metade das pessoas afetadas recebe
                                   tratamentos eficazes para a depressão
                              </strong>{' '}
                              – em alguns países, esse índice é menor que 10%. O
                              estigma envolvido, falta de recursos e de
                              informação, escassez de profissionais preparados
                              para lidar com o tema são alguns dos fatores que
                              acarretam esses números alarmantes.
                         </p>
                         <p className="m-15">
                              Ainda há muita confusão sobre o exatamente
                              significa essa palavra. Existe uma incompreensão
                              por grande parte da população sobre a relação de
                              tristeza e depressão, sobre os sintomas causados,
                              sobre o tratamento, e até sobre quem está passível
                              de desenvolvê-la. É isso que vamos explorar aqui.
                         </p>

                         <h2 className="subtitle2">Depressão não é tristeza</h2>
                         <p className="m-15">
                              Tristeza é um sentimento completamente normal.
                              Incontáveis vezes na vida, é esperado que uma
                              pessoa vá se sentir triste. A morte de um ente
                              querido, ser demitido do emprego, término de um
                              relacionamento, brigas familiares… Todas são
                              situações nas quais é plenamente normal se sentir
                              triste. No entanto, a tristeza acarretada por
                              esses momentos difíceis na vida não é o mesmo que
                              estar depressivo.
                         </p>
                         <p className="m-15">
                              A depressão é uma{' '}
                              <strong>alteração de humor</strong>, segundo a
                              DSM-V (Manual de Diagnóstico e Estatística de
                              Transtornos Mentais) produzido pela associação
                              americana de psiquiatria. Quando se está triste, é
                              possível apontar um motivo específico (ou vários
                              motivos), seja ele mais ou menos sério. Além
                              disso, é perfeitamente possível sentir momentos de
                              alegria intercalados com períodos de tristeza
                              frente às dificuldades que a vida apresenta.
                              Pessoas depressivas, por outro lado, ficam tristes
                              com tudo o tempo todo – todas as suas interações
                              com o mundo são filtradas pelo véu dessa situação.
                         </p>
                         <p className="m-15">
                              A depressão não necessariamente requer que algo
                              tenha acontecido. Muitas vezes, na teoria, a vida
                              da pessoa está ótima; e mesmo assim, o depressivo
                              se sente terrivelmente mal. Paralelamente, ela
                              mexe com a autoestima da pessoa. Junto com
                              sentimentos de tristeza, o indivíduo pode
                              experimentar algum tipo de autoaversão e queda na
                              autoconfiança e autoestima. Se simplesmente se
                              sentir triste não configura depressão, quais são
                              os reais sintomas que uma pessoa depressiva
                              experimenta?
                         </p>

                         <h2 className="subtitle marrom">
                              Quais são os sintomas da depressão?
                         </h2>
                         <p className="m-15">
                              Antes de tudo, é preciso ressaltar que apenas um
                              profissional de saúde mental preparado pode dar um
                              diagnóstico definitivo. No entanto, alguns
                              sintomas são bastante relacionados à depressão e
                              devem ser levados a sério caso você ou alguém
                              próximo se identifique com eles.{' '}
                         </p>
                         <p className="m-15">
                              Para caracterizar um quadro depressivo, esses
                              sintomas devem ser recorrentes – no mínimo duas
                              semanas de persistência. A partir desse período, é
                              possível começar a pensar se depressão está dentro
                              das possibilidades.{' '}
                              <strong className="marrom">
                                   {' '}
                                   O sintomas podem ser separados, para melhor
                                   compreensão, em algumas categorias.
                              </strong>
                         </p>
                         <p className="m-15 marrom">Comportamento</p>

                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   não socializar mais;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   não produzir na escola/trabalho;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   isolamento de família próxima e amigos;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   alto uso de álcool ou sedativos;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   não praticar atividades que costumavam ser
                                   agradáveis;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   difícil de se concentrar;
                              </li>
                         </ul>

                         <p className="m-15 marrom">Sentimentos</p>
                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   exaustão;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   culpa;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   irritação;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   frustração;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   falta de confiança;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   infelicidade;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   indecisão;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   desânimo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   tristeza;
                              </li>
                         </ul>
                         <p className="m-15 marrom">Pensamentos</p>
                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   Sou um fracasso;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   Tudo é minha culpa;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   Nada de bom nunca acontece comigo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   Eu sou inútil;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   Ninguém gosta de mim;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>A vida não vale a
                                   pena;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   As pessoas estariam melhor sem mim;
                              </li>
                         </ul>
                         <p className="m-15 marrom">Físicos</p>
                         <ul>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   estar cansado o tempo todo;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   ficar muito doente e abatido;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   ter dores de cabeça e musculares;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   dormir muito ou muito pouco;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   mudança ou perda de apetite;ter dores de
                                   cabeça e musculares;
                              </li>
                              <li className="m-15">
                                   <p className="circle"></p>
                                   perda ou ganho significativa de peso;
                              </li>
                         </ul>
                         <h2 className="subtitle">
                              Prevenção e tratamento precoce.
                         </h2>
                         <p className="m-15">
                              É possível prevenir a depressão? Esse é um assunto
                              ainda muito em debate dentro da psicologia e
                              psiquiatria, mas estudos recentes mostram cada vez
                              mais que existem sim, maneiras de prevenir – ou
                              pelo
                              <strong>
                                   {' '}
                                   práticas que diminuem consideravelmente a
                                   incidência de depressão.
                              </strong>{' '}
                              Vamos conferir algumas dessas práticas.
                         </p>

                         <h2 className="subtitle2">1 - Terapia</h2>
                         <p className="m-15">
                              A primeira prática é o acompanhamento terapêutico
                              com profissional de psicologia. Estudos mostram
                              que intervenções psicológicas preventivas podem
                              diminuir a incidência do transtorno, especialmente
                              em casos de recaídas. Outras ferramentas
                              terapêuticas também tem se provado eficazes, com{' '}
                              <strong>
                                   diminuição de até 50% da incidência de
                                   depressão
                              </strong>{' '}
                              em comparação com o grupo de controle.
                         </p>
                         <h2 className="subtitle2">2 - Exercício físico</h2>
                         <p className="m-15">
                              Uma das melhores ações para se tomar para prevenir
                              depressão é a{' '}
                              <strong>prática de exercício físico.</strong> Com
                              liberação de vários neurotransmissores no cérebro
                              que levam o cérebro a estados de maior
                              relaxamento, se exercitar também é essencial na
                              prevenção – e até no tratamento – do transtorno
                              depressivo.
                         </p>
                         <h2 className="subtitle2">3 - Dormir bem</h2>

                         <p className="m-15">
                              Ter uma boa noite de sono é extremamente
                              revigorante. Dormir um número razoável de horas
                              com constância, no entanto, é ainda mais
                              importante que simplesmente acordar descansado.
                              Estudos relacionam a incidência de insônia com
                              maior índice de depressão;{' '}
                              <strong>
                                   dormir bem à noite, portanto, pode estar
                                   relacionado com a prevenção de transtornos
                                   depressivos.
                              </strong>
                         </p>
                         <h2 className="subtitle2">4 - Redes sociais</h2>

                         <p className="m-15">
                              Aqui no blog nós já falamos sobre os problemas
                              relacionando saúde mental com a depressão. Por
                              isso, manter o uso do celular e dos aplicativos em
                              um nível mais baixo também ajuda a prevenir a
                              depressão, ansiedade, etc.
                         </p>
                         <h2 className="subtitle2">5 – Alimentação</h2>

                         <p className="m-15">
                              Uma dieta balanceada e saudável é essencial para
                              uma vida equilibrada; mas além disso, ela tem
                              impacto direto em um possível caso de depressão.
                              Por exemplo:{' '}
                              <strong>
                                   dietas com alto índice de gordura alteram o
                                   comportamento
                              </strong>{' '}
                              e podem aumentar o risco de depressão e outras
                              doenças psiquiátricas.
                         </p>
                         <h2 className="subtitle">
                              É possível tratar a depressão?
                         </h2>
                         <p className="m-15">
                              O diagnóstico de depressão, assim como de qualquer
                              outra doença, deve ser feito por um especialista –
                              nesse caso, por um profissional da saúde mental.
                              Procurar ajuda especializada é essencial, pois o
                              diagnóstico é complexo e é importante eliminar
                              outros diagnósticos com sintomas parecidos.
                         </p>
                         <p className="m-15">
                              Existem alguns questionários que ajudam o
                              profissional a entender a severidade da depressão.
                              A Escala de Avaliação de Depressão de Hamilton,
                              por exemplo, consiste em 21 questões que
                              resultarão em um grau de gravidade da depressão
                         </p>
                         <p className="m-15">
                              Quanto ao tratamento, eles existem de forma
                              efetiva tanto para depressão moderadas quanto
                              severas. Terapias as mais variadas, como a Terapia
                              Cognitivo Comportamental podem auxiliar no
                              tratamento da depressão dos mais diversos graus.
                              Outra ferramenta que existe são as medicações
                              antidepressivas, que podem ser adequadas em alguns
                              casos; no entanto, seu uso deve ser extremamente
                              controlado. Esse é outro motivo pelo qual o
                              diagnóstico deve ser feito por um profissional da
                              saúde mental – somente essa pessoa poderá tanto
                              avaliar quanto recomendar o melhor tratamento para
                              o caso específico.
                         </p>

                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Depressao;
