import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../components/Transition';
import gsap from 'gsap';
import Navbar from '../../components/Navbar';
import { RiWhatsappLine, RiMapPin2Line } from 'react-icons/ri';
import { MdOutlineEmail } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import './estrutura.css';
import Footer from '../../components/Footer';

//fotos
import { default as img } from '../../assets/images/estrutura/1.jpeg';
import { default as img2 } from '../../assets/images/estrutura/2.jpeg';
import { default as img3 } from '../../assets/images/estrutura/3.jpeg';
import { default as img4 } from '../../assets/images/estrutura/4.jpeg';
import { default as img5 } from '../../assets/images/estrutura/5.jpeg';
import { default as img6 } from '../../assets/images/estrutura/6.jpeg';
import { default as img7 } from '../../assets/images/estrutura/7.png';
import Slider from 'react-slick';

const Estrutura = () => {
     const estrutura = gsap.timeline();
     const estruturaImg = useRef(null);

     useEffect(() => {
          estrutura.from(
               estruturaImg.current,
               {
                    duration: 0.45,
                    x: -200,
                    opacity: 0,
               },
               '-=3'
          );
          setTimeout(() => {
               document.querySelector('.loading').style.display = 'none';
          }, 800);
     });

     const slideEstrutura = () => {
          const settings = {
               dots: true,
               infinite: true,
               speed: 500,
               slidesToShow: 1,
               slidesToScroll: 1,
               className: 'slide-estrutura',
          };
          return (
               <div>
                    <Slider {...settings}>
                         <div>
                              <img src={img2} alt="img2Slider" />
                         </div>
                         <div>
                              <img src={img} alt="imgSlider" />
                         </div>
                         <div>
                              <img src={img3} alt="img3Slider" />
                         </div>
                         <div>
                              <img src={img4} alt="img4Slider" />
                         </div>
                         <div>
                              <img src={img5} alt="img5Slider" />
                         </div>
                         <div>
                              <img src={img6} alt="img6Slider" />
                         </div>
                    </Slider>
               </div>
          );
     };

     return (
          <div id="estrutura">
               <Transition timeline={estrutura} />

               <Navbar />
               <main className="inside-estrutura" ref={estruturaImg}>
                    <h1 className="title">Estrutura</h1>
                    <h3>
                         Nós da <span>Raízes Psicologia</span> preparamos um
                         ambiente pensando no seu aconchego e bem-estar.
                    </h3>

                    {slideEstrutura()}

                    <h1 className="title">Localização</h1>
                    <div className="container-localizacao">
                         <h2>Fale Conosco</h2>
                         <div className="part1">
                              <p>
                                   Avenida Barão do Rio Branco, nª 2001/1706
                                   Juiz de Fora – MG CEP:26013-020
                              </p>

                              <div className="contato-part1">
                                   <p>
                                        {' '}
                                        <RiWhatsappLine
                                             size={15}
                                             color="#fff"
                                             className="tel"
                                        />
                                        (21) 99823-4720
                                   </p>
                                   <p>
                                        {' '}
                                        <RiWhatsappLine
                                             size={15}
                                             color="#fff"
                                             className="tel"
                                        />
                                        (21) 99994-4236
                                   </p>
                                   <p>
                                        {' '}
                                        <MdOutlineEmail
                                             size={15}
                                             color="#fff"
                                             className="tel"
                                        />
                                        contato@psicologiaraizes.com.br
                                   </p>
                              </div>
                         </div>
                         <a
                              href="https://goo.gl/maps/yzTt5ReKVTy6rqNw8"
                              target={'_blank'}
                         >
                              <RiMapPin2Line
                                   size={20}
                                   color="#fff"
                                   className="tel"
                              />
                              Como Chegar (Rota no GPS)
                         </a>
                         <div className="part2">
                              <a
                                   target="_blank"
                                   rel="noreferrer"
                                   href={'https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'}
                                   className="btn btn-localizacao"
                              >
                                   <FiPhone
                                        size={15}
                                        color="#fff"
                                        className="tel"
                                   />
                                   Entrar em contato
                              </a>

                              <img
                                   src={img7}
                                   alt="img7"
                                   width={180}
                                   onClick={() =>
                                        window.open(
                                             'https://goo.gl/maps/yzTt5ReKVTy6rqNw8'
                                        )
                                   }
                              />
                         </div>
                    </div>
               </main>
               <Footer />
          </div>
     );
};

export default Estrutura;
