import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/ansiedade.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Ansiedade = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Ansiedade: 22 sintomas do transtorno de ansiedade e
                         TUDO o que você precisa saber sobre o assunto
                    </h1>
                    <h3 className="date">
                         06 de Fevereiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>
                              Ansiedade: 22 sintomas do transtorno de ansiedade
                              e TUDO o que você precisa saber sobre o assunto
                         </p>
                    </h3>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>
                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <p className="m-15">
                              A <strong>ansiedade</strong> é uma das questões
                              que mais prejudica a qualidade de vida das pessoas
                              nos tempos atuais. Mas como identificar seus
                              sintomas? Como saber se você está enfrentando
                              crises de ansiedade?
                         </p>
                         <p className="m-15">
                              Nem sempre é fácil discernir se o que se sente é
                              ansiedade ou algo diferente.
                         </p>

                         <p className="m-15">
                              {' '}
                              Muitas pessoas, em meio a uma crise de ansiedade,
                              pensam que estão tendo um ataque cardíaco ou até
                              mesmo um AVC.
                         </p>
                         <p className="m-15">
                              Para saber mais sobre esse assunto tão importante,
                              dê uma olhada neste artigo até o final!
                         </p>

                         <h2 className="subtitle">
                              O que são transtornos de ansiedade
                         </h2>
                         <p className="m-15">
                              Ansiedade é um sentimento ligado à preocupação,
                              nervosismo e medo intenso. Apesar de ser uma
                              reação natural do corpo, a ansiedade pode virar um
                              distúrbio quando passa a atrapalhar nosso dia a
                              dia.
                         </p>
                         <p className="m-15">
                              De fato, os transtornos de ansiedade são mais
                              comuns do que se imagina.
                         </p>
                         <p className="m-15">
                              De acordo com a{' '}
                              <strong>
                                   Organização Mundial da Saúde (OMS)
                              </strong>
                              cerca de 264 milhões de pessoas sofrem de algum
                              transtorno de ansiedade ao redor do mundo.
                         </p>
                         <p className="m-15">
                              Mas, cá entre nós, ter um pouco de ansiedade no
                              dia a dia é normal. Certo?
                         </p>
                         <p className="m-15"></p>
                         <p className="m-15">
                              Por exemplo, nada mais normal que se sentir
                              ansiedade antes de uma prova importante ou antes
                              de um encontro com uma pessoa que você nunca viu
                              anteriormente!
                         </p>
                         <p className="m-15">
                              O que é preciso entender é que a principal
                              diferença da ansiedade normal do transtorno de
                              ansiedade é que este último causa prejuízos na
                              vida (por exemplo, <strong>absenteísmo</strong>)
                              de quem sofre e na vida das pessoas ao redor.
                         </p>
                         <p className="m-15">
                              Embora haja diversos tipos de transtornos de
                              ansiedade, é possível encontrar um padrão entre
                              eles.
                         </p>
                         <p className="m-15">
                              Assim, para que você entenda melhor, separamos
                              alguns dos sintomas mais comuns de um transtorno
                              de ansiedade.
                         </p>

                         <h2 className="subtitle">
                              22 sintomas físicos e psicológicos do transtorno
                              de ansiedade
                         </h2>

                         <p className="m-15">
                              As causas da ansiedade variam de pessoa para
                              pessoa e essas nuances são verificadas após a
                              avaliação de um profissional. No entanto, há
                              alguns fatores que podem facilitar o caminho para
                              esse distúrbio:
                         </p>

                         <p className="m-15">
                              É preciso ficar atento a esses{' '}
                              <strong>sintomas de ansiedade</strong> e caso eles
                              passem a atrapalhar seu dia a dia você deve
                              procurar ajuda.
                         </p>
                         <p className="m-15">
                              <span className="marrom">1.</span>Respiração
                              ofegante e falta de ar
                         </p>

                         <p className="m-15">
                              <span className="marrom">2.</span>Palpitações e
                              dores no peito
                         </p>
                         <p className="m-15">
                              <span className="marrom">3.</span>Fala acelerada
                         </p>
                         <p className="m-15">
                              <span className="marrom">4.</span>Sensação de
                              tremor e vontade de roer as unhas
                         </p>
                         <p className="m-15">
                              <span className="marrom">5.</span>Agitação de
                              pernas e braços
                         </p>
                         <p className="m-15">
                              <span className="marrom">6.</span>Tensão muscular
                         </p>
                         <p className="m-15">
                              <span className="marrom">7.</span>Tontura e
                              sensação de desmaio
                         </p>
                         <p className="m-15">
                              <span className="marrom">8.</span>Enjoo e vômitos
                         </p>
                         <p className="m-15">
                              <span className="marrom">9.</span>Irritabilidade
                         </p>
                         <p className="m-15">
                              <span className="marrom">10.</span>Enxaquecas
                         </p>
                         <p className="m-15">
                              <span className="marrom">11.</span>Boca seca e
                              hipersensibilidade de paladar
                         </p>

                         <p className="m-15">
                              <span className="marrom">12.</span>Insônia
                         </p>
                         <p className="m-15">
                              <span className="marrom">13.</span>Preocupação
                              excessiva
                         </p>
                         <p className="m-15">
                              <span className="marrom">14.</span>Dificuldade de
                              concentração
                         </p>
                         <p className="m-15">
                              <span className="marrom">15.</span>Nervosismo
                         </p>
                         <p className="m-15">
                              <span className="marrom">16.</span>Medo constante
                         </p>
                         <p className="m-15">
                              <span className="marrom">17.</span>Sensação de que
                              vai perder o controle ou que algo ruim vai
                              acontecer
                         </p>
                         <p className="m-15">
                              <span className="marrom">18.</span>Desequilíbrio
                              dos pensamentos
                         </p>
                         <p className="m-15">
                              <span className="marrom">19.</span>Braço dormente
                         </p>
                         <p className="m-15">
                              <span className="marrom">20.</span>Suor frio
                         </p>
                         <p className="m-15">
                              <span className="marrom">21.</span>Sensação de ser
                              um observador externo da própria vida
                              (despersonalização)
                         </p>
                         <p className="m-15">
                              <span className="marrom">22.</span>Sentir-se
                              desconectado de seus ambientes (desrealização)
                         </p>

                         <p className="m-15">
                              Sobre isso, o psicólogo Massashi Saito cita:
                         </p>
                         <h3 className="citacoes">
                              É importante entender que ansiedade não é algo tão
                              ruim. Ou seja, uma pessoa pode se sentir ansiosa
                              quando acha que existe um risco futuro ou uma
                              ameaça. No entanto, o problema é quando você perde
                              o controle e a duração desse sentimento bem como
                              não ter o controle daquilo que é real.
                         </h3>

                         <h2 className="subtitle">O que causa ansiedade?</h2>
                         <p className="m-15">
                              A causa da ansiedade nada mais é que uma reação
                              normal do corpo diante de eventos futuros.
                         </p>
                         <p className="m-15">
                              É um tipo de adaptação evolutiva que o ser humano
                              encontrou para que sua chance de sobrevivência
                              aumentasse.
                         </p>
                         <p className="m-15">
                              Assim, de certa forma um pouco de ansiedade é até
                              benéfico para as pessoas.
                         </p>
                         <p className="m-15">
                              Por exemplo, a ansiedade de ir mal na prova do
                              concurso pode te levar a estudar e se preparar
                              melhor para o dia do exame.
                         </p>
                         <p className="m-15">
                              Além disso, pensar no que pode dar errado em um
                              passeio, por exemplo, pode te ajudar a se planejar
                              melhor e desfrutar mais esse momento.
                         </p>
                         <p className="m-15">
                              Contudo, há um limite para essa ansiedade “do
                              bem”.{' '}
                         </p>
                         <p className="m-15">
                              Aliás, muitas vezes é uma linha tênue entre o
                              transtorno e a ansiedade comum.{' '}
                         </p>
                         <p className="m-15">
                              O sinal de alerta deve ser quando um pequeno
                              evento do dia a dia causa uma ansiedade
                              desproporcional. Isso certamente é um transtorno.
                         </p>
                         <p className="m-15">
                              A causa do transtorno de ansiedade não é
                              completamente conhecida.
                         </p>
                         <p className="m-15">
                              No entanto, há evidências científicas de que uma
                              série de <strong>fatores de risco</strong> podem
                              desencadear um transtorno de ansiedade. Dentre
                              eles:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>Genes específicos
                                   ligados à ansiedade (se um parente de
                                   primeiro grau teve algum transtorno a
                                   possibilidade de desenvolver também é bem
                                   maior)
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Fatores ambientais
                                   (trabalho muito estressante, rotina de vida
                                   agitada)
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Tipo de
                                   personalidade (algumas pessoas tem uma base
                                   ansiosa, ou seja, sua própria personalidade
                                   as coloca em risco de desenvolver um
                                   transtorno)
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Sexo e gênero
                                   (mulheres tem duas vezes mais chances de
                                   desenvolver um transtorno de ansiedade)
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Trauma (um evento
                                   de alto impacto emocional como, por exemplo,
                                   abusos são fatores de risco para transtornos
                                   de ansiedade)
                              </li>
                         </ul>

                         <h2 className="subtitle">
                              Quais são os tipos de ansiedade?
                         </h2>
                         <p className="m-15">
                              Existem{' '}
                              <strong>5 tipos principais de ansiedade,</strong>{' '}
                              são eles:
                         </p>
                         <h2 className="subtitle2">
                              Transtorno de ansiedade generalizada
                         </h2>
                         <p className="m-15">
                              O{' '}
                              <strong>
                                   Transtorno de ansiedade generalizada (TAG)
                              </strong>{' '}
                              é a combinação de uma preocupação excessiva com o{' '}
                              <strong>estresse</strong> recorrente. Ou seja, a
                              ansiedade passa a interferir na rotina.
                         </p>

                         <h2 className="subtitle2">Transtorno do pânico</h2>
                         <p className="m-15">
                              A <strong>síndrome do pânico</strong> é um
                              transtorno de ansiedade. Nesse caso, a pessoa
                              sente uma sensação forte de que está prestes a
                              morrer e perdendo o controle. Além disso, a
                              sensação pode ser descrita como um ataque do
                              coração, mesmo que não haja um verdadeiro sinal de
                              perigo.
                         </p>

                         <h2 className="subtitle2">Fobia social</h2>

                         <p className="m-15">
                              A fobia social, ou{' '}
                              <strong>ansiedade social</strong>, é um dos tipos
                              mais comuns e acontece sempre quando se está em
                              público. Além disso, há também outros tipos de
                              <strong>fobia</strong>, como a{' '}
                              <strong>claustrofobia</strong>, medo de locais
                              fechados, <strong>agorafobia</strong>, medo de
                              ficar sozinho em locais públicos, aracnofobia,
                              medo de aranhas,
                              <strong>tripofobia</strong>, medo de buracos, que
                              também fazem parte desses transtornos da
                              ansiedade. No entanto, esses são referente a
                              objetos, situações e pessoas.
                         </p>

                         <h2 className="subtitle2">
                              Transtorno obsessivo-compulsivo
                         </h2>

                         <p className="m-15">
                              O{' '}
                              <strong>
                                   Transtorno obsessivo-compulsivo ou TOC
                              </strong>{' '}
                              é um distúrbio psiquiátrico. Nesse caso, é o medo
                              de perder o controle ou ser responsável por algo
                              terrível para si ou para os outros, como a{' '}
                              <strong>culpa</strong>. Além disso, é
                              caracterizado por movimentos repetitivos e
                              comportamentos compulsivos.
                         </p>
                         <h2 className="subtitle2">
                              Transtorno de estresse pós-traumático
                         </h2>

                         <p className="m-15">
                              O Transtorno de estresse pós-traumático ou{' '}
                              <strong>TEPT</strong> é causado por um trauma ou
                              evento terrível. Ou seja, a pessoa passa por
                              momentos de confusão e medo, recordando os mesmos
                              sentimentos que sentiu durante o ocorrido.
                         </p>

                         <h2 className="subtitle">
                              O que é um ataque de ansiedade
                         </h2>
                         <p className="m-15">
                              Um ataque de ansiedade (ou crise de ansiedade) é
                              uma exacerbação dos sintomas que se manifestam por
                              meio de alguns sinais e sintomas típicos.
                         </p>
                         <p className="m-15">
                              Por exemplo, em uma crise de ansiedade é comum
                              sentir o coração acelerado, a respiração fica
                              ofegante, há a sensação de que algo horrível está
                              prestes a acontecer. Outros sintomas são:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>sensação de desmaio
                                   ou tontura
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>falta de ar
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>boca seca
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>suar frio
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>calafrios
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>preocupação
                                   excessiva
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>inquietude
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>angústia
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>medo
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>formigamento
                                   (sobretudo do braço e pescoço)
                              </li>
                         </ul>

                         <p className="m-15">
                              Muitas vezes, durante um ataque de ansiedade, a
                              pessoa que sofre procura um pronto socorro porque
                              acredita que está à beira de falecer.{' '}
                         </p>
                         <p className="m-15">
                              Nesses casos, após realizar um eletrocardiograma e
                              não se notar nenhuma alteração, o médico verifica
                              que o sofrimento é fruto de uma crise de
                              ansiedade.
                         </p>

                         <h2 className="subtitle">Como evitar a ansiedade?</h2>

                         <p className="m-15">
                              Ter um estilo de vida saudável é uma boa dica para
                              reduzir a ansiedade, assim como a mudança de
                              hábitos diários. É importante lembrar que a saúde
                              do ser humano é composta por uma série de pilares.
                              Entre eles estão a parte biológica, psíquica e
                              social.
                         </p>
                         <p className="m-15">
                              Sendo assim, fique atento a essas dicas que podem
                              te ajudar a controlar e evitar a ansiedade:
                         </p>

                         <h2 className="subtitle2">#1 Ir para a cama cedo</h2>

                         <p className="m-15">
                              <strong>Dormir mal</strong> pode ser um grande
                              culpado da ansiedade. Ou seja, sono inadequado
                              pode ampliar as reações antecipadas do cérebro,
                              aumentando os níveis de ansiedade.
                         </p>
                         <h2 className="subtitle2">
                              #2 Utilize música para relaxar
                         </h2>
                         <p className="m-15">
                              Quando estamos estressados, nada melhor que ouvir
                              uma música para relaxar, não acha? De fato,
                              estudos científicos mostram que a música aumenta a
                              liberação de uma série de neurotransmissores
                              ligados ao prazer. Em especial, ouvir seu som
                              favorito pode liberar dopamina, um
                              neurotransmissor ligado à sensação de recompensa.
                         </p>
                         <h2 className="subtitle2">
                              #3 Acorde 15 minutos mais cedo
                         </h2>
                         <p className="m-15">
                              Como a maioria das pessoas ansiosas, você
                              provavelmente está correndo pela manhã e gritando
                              com todos a sua volta “Vamos atrasar!”. Então,
                              diminua o ritmo e tente se preparar para um dia
                              relaxado pela frente. No entanto, se você começar
                              a se preocupar com a lista de tarefas, respire
                              fundo e pense, há tempo suficiente.
                         </p>
                         <h2 className="subtitle2">
                              #4 Reduza cafeína, açúcar e alimentos processados
                         </h2>
                         <p className="m-15">
                              A cafeína e baixos níveis de açúcar no sangue
                              podem causar palpitações cardíacas. Além disso,
                              esses alimentos podem ser ruins para a sua saúde e
                              atrapalhar no controle da ansiedade.
                         </p>
                         <h2 className="subtitle2">
                              #5 Faça atividades físicas
                         </h2>
                         <p className="m-15">
                              Assim como para qualquer outro tratamento,
                              movimentar o corpo e ter uma atividade física como
                              hobby pode ajudar muito para um dia a dia mais
                              produtivo e menos ansioso.
                         </p>
                         <h2 className="subtitle2">#6 Não se cobre tanto</h2>
                         <p className="m-15">
                              Um dos trabalhos mais difíceis de um terapeuta é
                              convencer uma pessoa ansiosa de que os sentimentos
                              de culpa e vergonha não precisam te dominar. Além
                              disso, você pode atrair ainda mais pensamentos
                              negativos, então foque nas coisas boas da vida.
                         </p>

                         <h2 className="subtitle2">#7 Procure ajuda</h2>

                         <p className="m-15">
                              Ir a um especialista não faz de você uma pessoa
                              doente, mas sim alguém que se cuida e que se
                              preocupa com o seu próprio bem-estar emocional.
                         </p>

                         <h2 className="subtitle2">#8 Pratique meditação</h2>
                         <p className="m-15">
                              Muitas pessoas vêm adotando a prática da meditação
                              ou <strong>mindfulness</strong> como um hábito
                              saudável. Além disso, essa é uma maneira poderosa
                              de combater o estresse e a ansiedade.
                         </p>
                         <h2 className="subtitle">
                              Acompanhamento profissional
                         </h2>
                         <p className="m-15">
                              A terapia, seja ela com um{' '}
                              <strong>psicólogo online</strong> ou presencial, é
                              a sua melhor aliada na hora de diminuir a
                              ansiedade e, enfim, tratá-la. Além disso, é o
                              método ideal para fazer as mudanças de vida que
                              você precisa.
                         </p>
                         <h2 className="subtitle">
                              Quais são os tratamentos mais comuns para
                              ansiedade
                         </h2>

                         <p className="m-15">
                              Existem algumas abordagens para o{' '}
                              <strong> tratamento da ansiedade</strong>, como
                              psicoterapia, e a combinação do tratamento
                              psicológico com medicamentos específicos, como os{' '}
                              <strong>ansiolíticos</strong>.
                         </p>
                         <p className="m-15">
                              Desse modo, para detalhar melhor as oportunidades
                              de tratamento, vamos esclarecer alguns pontos
                              sobre terapia e medicamentos.
                         </p>
                         <h2 className="subtitle2">Psicoterapia</h2>
                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>Psicanálise
                                   freudiana: baseada nos pensamentos de{' '}
                                   <strong>Freud</strong>, esse tipo de terapia
                                   é baseada no autoconhecimento. Além disso,
                                   foca no inconsciente para trazer os problemas
                                   para o consciente. É bastante utilizada em
                                   casos de ansiedade, pois ela busca na raiz
                                   dos pensamentos qual é o gatilho desse
                                   transtorno.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Psicanálise
                                   lacaniana: tem como foco a linguagem. Ou
                                   seja, parte dela para chegar à essência do
                                   que somos, ao nosso ser.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Psicanálise
                                   junguiana: Busca o autoconhecimento e procura
                                   reconhecer o que temos como essência. Ou
                                   seja, o tratamento é feito por símbolos,
                                   utilizando os sonhos e imagens oníricas.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Gestalt: trabalha a
                                   pessoa e as suas relações no ambiente em que
                                   ela está vive, levando ela a se tornar o
                                   observador da sua própria existência para
                                   enfim conseguir enxergar o todo. É também
                                   considerada uma terapia holística, ou seja,
                                   ela procura ver o todo.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Terapia
                                   cognitivo-comportamental (TCC): trabalha com
                                   foco na em problemas específicos, como por
                                   exemplo traumas e fobias.
                              </li>
                         </ul>
                         <p className="m-15">
                              Além disso, se quiser saber mais sobre{' '}
                              <strong>tipos de terapia</strong> confira nosso
                              post completo sobre isso.
                         </p>
                         <p className="m-15">Medicamentos: </p>
                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>Ansiolíticos</strong>: ajudam a
                                   amenizar os sintomas, mas não trabalha a
                                   causa do problema. Os ansiolíticos agem de
                                   muitas formas e isso está ligado com a
                                   atuação no sistema de neurotransmissão. Além
                                   disso, muitos desses remédios aliviam
                                   sintomas físicos em fases mais graves da
                                   doença e os níveis de hiperatividade
                                   cerebral.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>Antidepressivos:</strong> não causam
                                   tanta dependência no paciente e possuem uma
                                   forma de tratar mais amena, sem grandes
                                   riscos. Por isso, os mais indicados são os
                                   antidepressivos que atuam na serotonina.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>Antipsicóticos: </strong> podem ser
                                   muito bons no tratamento em momentos mais
                                   críticos, e assim como os ansiolíticos, não
                                   tratam a causa do problema, apenas aliviam
                                   sintomas emocionais e físicos.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Terapia
                                   cognitivo-comportamental (TCC): trabalha com
                                   foco na em problemas específicos, como por
                                   exemplo traumas e fobias.
                              </li>
                         </ul>
                         <p className="m-15">
                              Além disso, não se preocupe caso esses nomes
                              pareçam difíceis à primeira vista. A melhor
                              indicação será mesmo de um especialista no
                              assunto. Por isso, você deve conversar com um{' '}
                              <strong>psicólogo online</strong> e descubra qual
                              o melhor caminho a seguir.
                         </p>
                         <h2>
                              Quais remédios naturais são usados para a
                              ansiedade?
                         </h2>
                         <p className="m-15">
                              Embora haja uma série de “receitas mágicas” de
                              chás para acabar com a ansiedade, é preciso dizer
                              que a grande maioria delas não são cientificamente
                              comprovadas.{' '}
                         </p>
                         <p className="m-15">
                              Assim, os “remédios” naturais consistem em cuidar
                              de seu corpo, participar de atividades saudáveis e
                              eliminar <strong>relacionamentos tóxicos</strong>.
                         </p>
                         <p className="m-15">
                              Entre essas medidas estão inclusas:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>Dormir o suficiente
                                   e adotando medidas para{' '}
                                   <strong>dormir melhor</strong>
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Meditar (a
                                   abordagem <strong>mindfullness</strong>{' '}
                                   comprovadamente reduz a ansiedade)
                              </li>

                              <li className="m-15 ">
                                   <p className="circle"></p>Permanecer ativo e
                                   se exercitar (a OMS recomenda, para ser uma
                                   pessoa ativa fisicamente, ao menos 150
                                   minutos exercícios moderados por semana)
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Ter dieta saudável
                                   e equilibrada
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Evitar o álcool e a
                                   cafeína
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>Não fumar
                              </li>
                         </ul>
                         <h2 className="subtitle">Conclusão</h2>
                         <p className="m-15">
                              Por fim, os sintomas de ansiedade são os mais
                              diversos possíveis. No entanto, durante uma crise
                              há alguns indicadores bem comuns.
                         </p>
                         <p className="m-15">
                              Se você sente ou conhece alguém que sinta sintomas
                              de ansiedade, a melhor maneira é buscar ajuda
                              especializada, pois a ansiedade tem cura com o
                              tratamento adequado.
                         </p>
                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Ansiedade;
