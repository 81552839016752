import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/1.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Angustia = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Angústia: o que é, quais sintomas e como lidar com ela
                    </h1>
                    <h3 className="date">
                         15 de janeiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>
                              Angústia: o que é, quais sintomas e como lidar com
                              ela
                         </p>
                    </h3>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>
                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <p className="m-15">
                              A <strong>angústia</strong> não é um termo
                              desconhecido para nós, mas talvez seja algo um
                              pouco mais difícil de definirmos emocionalmente as
                              questões que envolvem esse sentimento.
                         </p>
                         <p className="m-15">
                              Em geral, sabemos identificar situações que
                              provocam angústia, mas não temos tantas
                              informações de como lidar com ela.
                         </p>

                         <p className="m-15">
                              A verdade é que a angústia é uma sensação
                              psicológica que provoca danos físicos e mentais, e
                              que pode estar associado a outros problemas
                              emocionais, como a <strong>depressão</strong>.
                         </p>
                         <p className="m-15">
                              Hoje vamos entender um pouco melhor sobre o que a
                              angústia pode provocar e quais são as principais
                              medidas para superá-la.{' '}
                         </p>

                         <h2 className="subtitle">O que é angústia?</h2>
                         <p className="m-15">
                              A angústia é considerada uma percepção psicológica
                              caracterizada pela{' '}
                              <strong>mudança de humor</strong>, perda de paz
                              interior, dor, insegurança, culpa, mal-estar e{' '}
                              <strong>tristeza</strong>.
                         </p>
                         <p className="m-15">
                              Ou seja, ela é a junção de questões emocionais e
                              físicas que podem chegar ao limite de nos impedir
                              de realizar tarefas cotidianas ou provocar
                              isolamento.
                         </p>
                         <p className="m-15">
                              Entre os especialistas em psicologia, a angústia
                              pode estar associada a outros problemas ligados ao{' '}
                              <strong>bem-estar emocional</strong> como a
                              depressão, e que pode dar origem a doenças{' '}
                              <strong>psicossomáticas</strong>.
                         </p>
                         <p className="m-15">
                              Para Letícia Medeiros, psicóloga e especialista da
                              Raízes Psicologia:
                         </p>
                         <h2 className="citacoes">
                              A angústia pode ser entendida como um série de
                              sensações que acontecem ao mesmo tempo: sensações
                              físicas, como falta de ar, tontura, pressão no
                              peito, aceleração nos batimentos cardíacos e
                              também psicológicas, como pensamentos negativos,
                              culpa, choro, medo, tristeza e ansiedade.
                         </h2>
                         <p className="m-15">
                              “Além disso, pessoas que sofrem com esse
                              sentimento, podem ter dificuldades, principalmente
                              nos momentos de crise, em enxergar saídas práticas
                              para seus problemas.”, completa Letícia.
                         </p>
                         <p className="m-15">
                              A angústia também pode estar associada a dúvidas
                              sobre o futuro, reais ou imaginárias.
                         </p>
                         <p className="m-15">
                              Como por exemplo: algo que estamos ansiosos para
                              que aconteça ou que não temos certeza sobre as
                              suas consequências ou situações do passado, como
                              relembrar experiências traumáticas.
                         </p>
                         <h2 className="subtitle">
                              Quais os sintomas da angústia?
                         </h2>
                         <p className="m-15">
                              Como pontuamos brevemente, a{' '}
                              <strong>angústia</strong> envolve sintomas físicos
                              e psicológicos e, em geral, podemos identificar
                              mais facilmente observando os seguintes fatores:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Excesso de pensamentos negativos
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Crises de{' '}
                                   <Link to={'/blog/especialista/ansiedade'}>
                                        <strong>
                                             <u>ansiedade</u>
                                        </strong>
                                   </Link>
                                   ;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Batimentos cardíacos rápidos e
                                   descontrolados;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Dores no peito e sensação de aperto na
                                   garganta;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Dificuldades para respirar e sensação de
                                   sufocamento;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Inquietação;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Dores de cabeça;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Insônia.
                              </li>
                         </ul>

                         <p className="m-15">Para a psicóloga Letícia: </p>
                         <h2 className="citacoes">
                              Nó na garganta, aperto no peito, desamparo e
                              vontade de chorar. Estas são sensações geralmente
                              mencionadas por pessoas que passam pela angústia.
                         </h2>

                         <h2 className="subtitle">Como melhorar a angústia?</h2>
                         <p className="m-15">
                              Você deve estar se perguntando, mas então,{' '}
                              <strong>como lidar com a angústia? </strong>Não há
                              exatamente uma receita de bolo ou exercícios que
                              garantam que você deixe de sentir aquela angústia
                              ou se livre dela para sempre.
                         </p>
                         <p className="m-15">
                              Esse sentimento tem muito a ver com a forma como
                              nos conhecemos, trabalhamos a nossa{' '}
                              <strong>inteligência emocional</strong> e lidamos
                              com as questões no dia a dia.
                         </p>
                         <p className="m-15">
                              Por isso é tão importante desenvolvermos
                              habilidades, como o{' '}
                              <strong>autoconhecimento</strong>, junto com um
                              profissional, para evitar que os sintomas cheguem
                              ao limite da situação.
                         </p>
                         <p className="m-15">Segundo Letícia: </p>
                         <h2 className="citacoes">
                              Para superar momentos de angústia, é importante
                              procurarmos pessoas e atividades que nos façam bem
                              e nos tragam paz. Amigos e familiares, nossos
                              lugares preferidos, comidas favoritas, contato com
                              ambientes positivos.
                         </h2>
                         <p className="m-15">
                              O nosso encontro com o <strong>bem-estar</strong>{' '}
                              emocional passa, entre outras questões, com os
                              fatores de risco da angústia durante toda a vida.
                         </p>
                         <p className="m-15">
                              Por isso, é importante buscar esse equilíbrio para
                              que momentos de maior dificuldade sejam
                              trabalhados sem tantos abalos emocionais e
                              físicos.
                         </p>
                         <h2 className="citacoes">
                              É importante cuidar de cada caso em sua
                              singularidade. O acompanhamento com um
                              profissional é recomendado sempre que a pessoa
                              encontra dificuldades em lidar com seus
                              sentimentos”
                         </h2>
                         <p className="m-15">
                              “Se deixarmos a <strong>angústia</strong> tomar
                              conta, nosso autocuidado diminui. Isso quer dizer
                              que deixarmos de fazer coisas importantes pra nós
                              mesmos. Aspectos de saúde como a higiene do sono,
                              alimentação e a evitação de abuso de substâncias,
                              como o álcool, precisam ficar no nosso radar de
                              saúde geral.”, finaliza Letícia.
                         </p>

                         <h2 className="subtitle">Dicas para superar</h2>
                         <p className="m-15">
                              Mesmo que seja muito importante o contato com um
                              especialista em{' '}
                              <strong>bem-estar emocional</strong> para tratar a
                              causa do seu sentimento, você pode seguir alguns
                              cuidados práticos para entender como{' '}
                              <strong>lidar com a angústia.</strong>
                         </p>
                         <p className="m-15">Veja:</p>
                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Controle a respiração, inspire e expire
                                   calmamente para que você retome o controle da
                                   sua respiração;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Faça exercícios físicos ou pratique outras
                                   técnicas que ajudam a relaxar o corpo, como a
                                   meditação e o alongamento;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Busque <strong>
                                        pensamentos positivos
                                   </strong>{' '}
                                   para substituir a carga mental negativa;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Tome um banho relaxante e descanse em
                                   ambientes calmos;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Inclua chás e uma alimentação saudável na sua
                                   rotina;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Converse, entre em contato com pessoas que te
                                   fazem bem, seja ela um amigo ou familiar ou o
                                   seu próprio terapeuta;
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Aprenda a viver todos os sentimentos que
                                   existem dentro de você e transforme em
                                   degraus para a sua evolução pessoal.
                              </li>
                         </ul>
                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Angustia;
