import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/diversidade.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Diversidade = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          const ac = new AbortController();
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
          return () => {
               ac.abort();
          };
     }, []);
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Diversidade nas empresas: o que é e como melhorá-la
                    </h1>
                    <h3 className="date">
                         01 de Fevereiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>
                              Diversidade nas empresas: o que é e como
                              melhorá-la
                         </p>
                    </h3>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>
                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <p className="m-15">
                              A <strong>diversidade</strong> nas empresas é
                              quando as equipes são compostas por pessoas de
                              diferentes gêneros, etnias, formações, idades,
                              vivências, visões etc. Essa diversidade é
                              essencial para promover maior igualdade,
                              criatividade e até mesmo mais sucesso para as
                              empresas.
                         </p>
                         <p className="m-15">
                              A diversidade é um tema muito discutido atualmente
                              e essa pauta chegou também ao mundo corporativo.
                              Cresce a preocupação em promover ambientes de
                              trabalho com mais espaço para pessoas de
                              diferentes culturas e ideias.
                         </p>

                         <p className="m-15">
                              Promover a diversidade nas empresas passou a ser
                              uma das prioridades do RH, graças aos benefícios
                              conquistados. Saiba mais sobre o assunto neste
                              post!{' '}
                         </p>

                         <h2 className="subtitle">
                              O que é diversidade e como ela pode ajudar a
                              empresa?
                         </h2>

                         <p className="m-15">
                              Uma equipe com diversidade pode ser entendida como
                              aquela composta por pessoas de diferentes
                              formações, gêneros, raças, experiências e visões
                              de mundo. A riqueza, principalmente de ideias e
                              percepções, abre portas para várias possibilidades
                              na solução de problemas e condução de tarefas.
                         </p>

                         <p className="m-15">
                              Investir na diversidade nas empresas traz diversas
                              vantagens: gera melhor gestão de riscos, abertura
                              de oportunidades para a inovação e aumento da
                              conexão com um público maior, formado por
                              diferentes culturas.
                         </p>
                         <p className="m-15">
                              Também contribui com o employer branding, ao
                              construir uma imagem de empresa livre de
                              preconceitos, que abraça as diferenças e tem
                              espaço para todos, além do desenvolvimento de um
                              clima organizacional mais propício ao crescimento
                              pessoal e profissional dos funcionários.
                         </p>

                         <p className="m-15">
                              No entanto, é importante lembrar que essa
                              diversidade só ocorre verdadeiramente quando há a
                              crença de que experiências diferentes trazem mais
                              riqueza ao ambiente de trabalho, contribuindo de
                              maneira significativa para o alcance de resultados
                              com qualidade.
                         </p>

                         <h2 className="subtitle">
                              Como promover a diversidade nas empresas?
                         </h2>
                         <p className="m-15">
                              Algumas ações podem ser tomadas para impulsionar a
                              diversidade nas empresas. Podemos destacar as
                              seguintes:
                         </p>

                         <h2 className="subtitle2">
                              Avalie e mensure o quadro de funcionários
                         </h2>

                         <p className="m-15">
                              A causa do transtorno de ansiedade não é
                              completamente conhecida.
                         </p>
                         <p className="m-15">
                              Procure fazer um diagnóstico claro do quadro de
                              funcionários na empresa e entenda como ele é
                              composto. Procure entender quais motivos levaram à
                              contratação daquelas pessoas e o que pode ser
                              feito para ampliar a diversidade na equipe.
                         </p>

                         <h2 className="subtitle2">
                              Trabalhe a cultura organizacional
                         </h2>
                         <p className="m-15">
                              A cultura da sua empresa valoriza a diversidade ou
                              procura sempre o mesmo tipo de profissional? Ela
                              abre espaço para a inovação e valoriza a inclusão?
                              É interessante observar esse quadro e até mesmo
                              fazer uma pesquisa de clima organizacional para
                              entender se há o sentimento de exclusão em alguns
                              grupos dentro do ambiente corporativo.
                         </p>

                         <h2 className="subtitle2">
                              Preste atenção aos processos de recrutamento
                         </h2>
                         <p className="m-15">
                              Procurar os próximos funcionários sempre na mesma
                              universidade, com a mesma formação ou dentro de um
                              grupo limitado deixa a empresa totalmente
                              homogênea e sem perspectiva de mudanças. Alguns
                              preconceitos também podem atrapalhar a seleção de
                              candidatos, o que chamamos de vieses
                              inconscientes.
                         </p>

                         <h2 className="subtitle2">
                              Invista na comunicação interna
                         </h2>
                         <p className="m-15">
                              Esse apoio à diversidade deve partir não só dos
                              valores da empresa, mas também do comportamento
                              dos funcionários. Trabalhe a comunicação interna
                              valorizando o respeito às diferenças e, se
                              necessário, promova treinamentos sobre
                              conscientização.
                         </p>
                         <p className="m-15">
                              Trabalhar o acolhimento entre os colaboradores é
                              importante e essa iniciativa deve partir da
                              organização.
                         </p>
                         <p className="m-15">
                              Ao trabalhar a diversidade nas empresas,
                              percebemos a construção de uma sociedade mais
                              inclusiva, refletindo a preocupação em combater o
                              preconceito. Claro que ainda há muito a ser feito,
                              mas o primeiro passo para uma realidade melhor é
                              um maior acolhimento às diferenças dentro do
                              ambiente de trabalho.
                         </p>
                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Diversidade;
