import React, { useEffect, useRef } from 'react';
import './videoconferencia.css';

import Navbar from '../../components/Navbar';
import Transition from '../../components/Transition';
import gsap from 'gsap';
import Footer from '../../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const VideoConferencia = () => {
     const video = gsap.timeline();
     const videoImg = useRef(null);

     useEffect(() => {
          video.from(
               videoImg.current,
               {
                    duration: 0.4,
                    y: -200,
                    opacity: 0,
               },
               '-=3'
          );
     });
     useEffect(() => {
          AOS.init();
          AOS.refresh();
          setTimeout(() => {
               document.querySelector('.loading').style.display = 'none';
          }, 800);
     }, []);

     return (
          <div id="videoConf">
               <Transition timeline={video} />
               <Navbar />
               <div className="inside-video" ref={videoImg}>
                    <div className="title-subtitle">
                         <h1>Eficácia de orientação psicológica online</h1>
                         <h4>
                              Estudo comprovam que a orientação psicológica
                              online é eficaz, aumenta a adesão ao tratamento e
                              gera satisfação para pacientes e profissionais.
                              Confira alguns exemplos
                         </h4>
                    </div>
                    <div className="eficacia-exemplos">
                         <div className="exemplo examplo1">
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="1000"
                                   data-aos-duration="800"
                                   data-aos-offset="50"
                              >
                                   Estudo identifica melhora da severidade da
                                   depressão em pacientes ao longo do tempo
                                   atendidos por videoconferência.
                              </div>
                              <p
                                   data-aos="fade-left"
                                   data-aos-delay="1000"
                                   data-aos-duration="800"
                                   data-aos-offset="50"
                              >
                                   Um estudo realizado para avaliar a
                                   efetividade de resultados de cuidados
                                   colaborativos baseado em telemedicina de 2007
                                   a 2009, em 364 pacientes com diagnóstico de
                                   depressão identificou expressiva redução na
                                   severidade do grau da doença ao longo do
                                   tempo. (Fortney JC et al, 2013)
                              </p>
                         </div>
                         <div className="exemplo exemplo2">
                              <p
                                   data-aos="fade-right"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Um estudo realizado em um programa de
                                   assistência familiar e de funcionários foi
                                   lançado em 2011. O objetivo era identificar
                                   os desfechos clínicos das modalidades de
                                   aconselhamento por videoconferência e
                                   pessoalmente. O estudo foi realizado com 68
                                   casos de atendimento online e outros 68 casos
                                   de atendimento presencial. Dados demonstraram
                                   que não houve diferenças nos resultados
                                   encontrados para o objetivo proposto, duração
                                   média do atendimento e interrompimento de
                                   tratamento. (Veder B et al., 2014)
                              </p>
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Estudo identifica resultados similares para
                                   atendimento presencial e online em saúde
                                   mental.
                              </div>
                         </div>
                         <div className="exemplo exemplo3">
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Consulta online por vídeo-conferência é tão
                                   eficaz quanto a presencial.
                              </div>
                              <p
                                   data-aos="fade-left"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Um estudo realizado pelo St. Vincent
                                   Hospital, em Sydney, que envolveu cerca de
                                   1.000 pessoas com ansiedade e depressão
                                   mostrou que as consultas online têm alcançado
                                   resultados ainda mais eficazes do que as
                                   consultas presenciais e, depois de seis
                                   meses, os resultados alcançados são mantidos
                                   constante. O sucesso do aconselhamento online
                                   é apoiado pelo fato de que menos pessoas
                                   abandonaram prematuramente a jornada
                                   psicológica.
                              </p>
                         </div>
                         <div className="exemplo exemplo4" id="exemplo4">
                              <p
                                   data-aos="fade-right"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Vários estudos mostram resultados positivos
                                   sobre a eficácia das consultas psicológicas
                                   online. Eles podem de fato demonstrar uma
                                   melhoria significativa em pessoas com
                                   síndrome do pânico (Bouchard S. et al, 2004),
                                   transtornos alimentares (Grunwald M. &
                                   Wesemann D, 2006), transtorno de estresse
                                   pós-traumático (Germain V. et al., 2009).
                              </p>
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Eficácia no tratamento de ataques de pânico,
                                   distúrbios alimentares, em situações de
                                   estresse pós-traumático e dor por consultas
                                   online.
                              </div>
                         </div>
                    </div>
                    <div className="eficacia-exemplos mobile">
                         <div className="exemplo examplo1">
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="1000"
                                   data-aos-duration="800"
                                   data-aos-offset="50"
                              >
                                   Estudo identifica melhora da severidade da
                                   depressão em pacientes ao longo do tempo
                                   atendidos por videoconferência.
                              </div>
                              <p
                                   data-aos="fade-left"
                                   data-aos-delay="1000"
                                   data-aos-duration="800"
                                   data-aos-offset="50"
                              >
                                   Um estudo realizado para avaliar a
                                   efetividade de resultados de cuidados
                                   colaborativos baseado em telemedicina de 2007
                                   a 2009, em 364 pacientes com diagnóstico de
                                   depressão identificou expressiva redução na
                                   severidade do grau da doença ao longo do
                                   tempo. (Fortney JC et al, 2013)
                              </p>
                         </div>
                         <div className="exemplo exemplo2">
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Estudo identifica resultados similares para
                                   atendimento presencial e online em saúde
                                   mental.
                              </div>
                              <p
                                   data-aos="fade-right"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Um estudo realizado em um programa de
                                   assistência familiar e de funcionários foi
                                   lançado em 2011. O objetivo era identificar
                                   os desfechos clínicos das modalidades de
                                   aconselhamento por videoconferência e
                                   pessoalmente. O estudo foi realizado com 68
                                   casos de atendimento online e outros 68 casos
                                   de atendimento presencial. Dados demonstraram
                                   que não houve diferenças nos resultados
                                   encontrados para o objetivo proposto, duração
                                   média do atendimento e interrompimento de
                                   tratamento. (Veder B et al., 2014)
                              </p>
                         </div>
                         <div className="exemplo exemplo3">
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Consulta online por vídeo-conferência é tão
                                   eficaz quanto a presencial.
                              </div>
                              <p
                                   data-aos="fade-left"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Um estudo realizado pelo St. Vincent
                                   Hospital, em Sydney, que envolveu cerca de
                                   1.000 pessoas com ansiedade e depressão
                                   mostrou que as consultas online têm alcançado
                                   resultados ainda mais eficazes do que as
                                   consultas presenciais e, depois de seis
                                   meses, os resultados alcançados são mantidos
                                   constante. O sucesso do aconselhamento online
                                   é apoiado pelo fato de que menos pessoas
                                   abandonaram prematuramente a jornada
                                   psicológica.
                              </p>
                         </div>
                         <div className="exemplo exemplo4" id="exemplo4">
                             
                              <div
                                   className="box"
                                   data-aos="zoom-in"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Eficácia no tratamento de ataques de pânico,
                                   distúrbios alimentares, em situações de
                                   estresse pós-traumático e dor por consultas
                                   online.
                              </div>
                              <p
                                   data-aos="fade-right"
                                   data-aos-delay="500"
                                   data-aos-duration="800"
                                   data-aos-offset="80"
                              >
                                   Vários estudos mostram resultados positivos
                                   sobre a eficácia das consultas psicológicas
                                   online. Eles podem de fato demonstrar uma
                                   melhoria significativa em pessoas com
                                   síndrome do pânico (Bouchard S. et al, 2004),
                                   transtornos alimentares (Grunwald M. &
                                   Wesemann D, 2006), transtorno de estresse
                                   pós-traumático (Germain V. et al., 2009).
                              </p>
                         </div>
                    </div>
                    <div
                         className="sigilo-profissional"
                         data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-aos-offset="200"
                    >
                         <h2>Sigilo Profissional</h2>
                         <div className="conselhos">
                              <p>
                                   Todos os softwares envolvidos nas sessões
                                   fazem uso de criptografia e conexões seguras.
                                   Contudo, os serviços mediados por
                                   computadores não podem ser considerados como
                                   totalmente sigilosos e seguros, pois são
                                   vulneráveis à ameaças digitais.
                              </p>

                              <p>
                                   Aconselha-se a não usar computadores públicos
                                   e apagar os históricos de conversações sempre
                                   após as sessões. É também importante proteger
                                   o seu computador com um programa de antivírus
                                   e firewall.
                              </p>
                         </div>
                    </div>
               </div>

               <Footer />
          </div>
     );
};

export default VideoConferencia;
