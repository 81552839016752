     import React, { useEffect, useState } from 'react';
     import './navbar.css';
     import PropTypes from 'prop-types';
     import { default as blob } from '../../assets/images/svg/blob.svg';
     import { default as onda } from '../../assets/images/svg/svgOnda.svg';

     import Lottie from 'react-lottie';
     import logo from '../../assets/images/logoicone.webp';
     import menuData from '../../assets/images/json/menu-open.json';
     import { Link } from 'react-router-dom';
     import $ from 'jquery';
     import AOS from 'aos';
     import 'aos/dist/aos.css';

     const Navbar = () => {
          const [pause, setPause] = useState(true);
          const [stop, setStop] = useState(true);
          const [direction, setDirection] = useState(false);
          const [directionFinal, setDirectionFinal] = useState(1);
          const [frame, setFrame] = useState(0);
          const defaultOptions = {
               loop: false,
               autoplay: false,
               animationData: menuData,
               rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
               },
          };
          defaultOptions.animationData.ip = 20;

          $(document).ready(function () {
               const menu = document.querySelector('.lot.desktop');
               $(window).scroll(function () {
                    if ($(this).scrollTop() > 100) {
                         menu.style.position = 'fixed';
                    } else {
                         menu.style.position = 'relative';
                    }
               });
          });
          useEffect(() => {
               AOS.init();
               AOS.refresh();
          });

          return (
               <>
                    <div className="container">
                         <section id="navbar">
                              <img
                                   className="logo"
                                   src={logo}
                                   width={115}
                                   height={115}
                                   data-aos="zoom-in-down"
                                   data-aos-delay="800"
                                   alt="logo"
                              />
                              <div
                                   className="menu-navbar"
                                   data-aos="zoom-in-down"
                                   data-aos-delay="800"
                              >
                                   <ul>
                                        <li>
                                             <Link
                                                  to={'/home'}
                                                  className="link-home"
                                             >
                                                  Home
                                             </Link>
                                        </li>
                                        <li>
                                             <Link
                                                  to={'/blog'}
                                                  className="link-blog"
                                             >
                                                  Blog
                                             </Link>
                                        </li>
                                        <li>
                                             <Link
                                                  to={'/estrutura'}
                                                  className="link-estrutura"
                                             >
                                                  Estrutura
                                             </Link>
                                        </li>
                                        <li>
                                             <Link
                                                  to={'/fale-conosco'}
                                                  className="link-fale"
                                             >
                                                  Fale conosco
                                             </Link>
                                        </li>
                                   </ul>
                              </div>
                              <div
                                   className="lot desktop"
                                   data-aos="zoom-in-down"
                                   data-aos-delay="800"
                                   onClick={() => {
                                        if (direction && directionFinal === 1) {
                                             setDirectionFinal(-1);
                                             setDirection(false);
                                        
                                        } else {
                                             setPause(!pause);
                                             setStop(!stop);
                                        }
                                        setTimeout(() => {
                                             setDirectionFinal(1);
                                             setPause(!pause);
                                             setStop(!stop);
                                        }, 350);
                                        if (document.querySelector('.menu-navbar')) {
                                             document
                                                  .querySelector('.menu-navbar')
                                                  .classList.toggle('hidden');
                                        }
                                        if (document.querySelector('.list-hidden')) {
                                             document
                                                  .querySelector('.list-hidden')
                                                  .classList.toggle('show');
                                        }

                                        if (
                                             document.querySelector(
                                                  '.list-hidden .lot'
                                             )
                                        ) {
                                             document
                                                  .querySelector('.list-hidden .lot')
                                                  .classList.toggle('show');
                                        }
                                   }}
                              >
                                   <Lottie
                                        options={defaultOptions}
                                        height={100}
                                        width={100}
                                        eventListeners={[
                                             {
                                                  eventName: 'enterFrame',
                                                  callback: (e) => {
                                                       setFrame(e.currentTime);
                                                  },
                                             },
                                             {
                                                  eventName: 'complete',
                                                  callback: (e) => {
                                                       if (
                                                            e.direction === 1 &&
                                                            directionFinal === 1
                                                       ) {
                                                            setDirection(true);
                                                       }
                                                  },
                                             },
                                        ]}
                                        direction={directionFinal}
                                        isStopped={stop}
                                        isPaused={pause}
                                   />
                              </div>
                              <div
                                   className="lot mobile"
                                   data-aos="zoom-in-down"
                                   data-aos-delay="800"
                                   onClick={() => {
                                        if (direction && directionFinal === 1) {
                                             setDirectionFinal(-1);
                                             setDirection(false);
                              
                                        } else {
                                             setPause(!pause);
                                             setStop(!stop);
                                        }
                                        setTimeout(() => {
                                             setDirectionFinal(1);
                                             setPause(!pause);
                                             setStop(!stop);
                                        }, 350);
                                        if (document.querySelector('.menu-navbar')) {
                                             document
                                                  .querySelector('.menu-navbar')
                                                  .classList.toggle('hidden');
                                        }

                                        if (document.querySelector('.list-hidden')) {
                                             document
                                                  .querySelector('.list-hidden')
                                                  .classList.toggle('show');
                                        }
                                        if (
                                             document.querySelector(
                                                  '.list-hidden .lot'
                                             )
                                        ) {
                                             document
                                                  .querySelector('.list-hidden .lot')
                                                  .classList.toggle('show');
                                        }
                                   }}
                              >
                                   <Lottie
                                        options={defaultOptions}
                                        height={100}
                                        width={100}
                                        eventListeners={[
                                             {
                                                  eventName: 'enterFrame',
                                                  callback: (e) => {
                                                       setFrame(e.currentTime);
                                                  },
                                             },
                                             {
                                                  eventName: 'complete',
                                                  callback: (e) => {
                                                       if (
                                                            e.direction === 1 &&
                                                            directionFinal === 1
                                                       ) {
                                                            setDirection(true);
                                                       }
                                                  },
                                             },
                                        ]}
                                        direction={directionFinal}
                                        isStopped={stop}
                                        isPaused={pause}
                                   />
                              </div>
                         </section>
                         <section className="list-hidden">
                              <ul>
                                   <li>
                                        <Link to="/home">Home</Link>
                                   </li>
                                   <li>
                                        <Link to="/blog">Blog</Link>
                                   </li>
                                   <li>
                                        <Link to="/estrutura">Estrutura</Link>
                                   </li>
                                   <li>
                                        <Link to="/fale-conosco">Fale conosco</Link>
                                   </li>
                              </ul>
                              <div className="footer-list-hidden">
                                   {/* <p>Raizes@gmail.com</p> */}
                                   <div className="icons-footer-list-hidden">
                                        <a href= 'https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'>
                                             <svg
                                                  width="30px"
                                                  height="30px"
                                                  preserveAspectRatio="xMidYMin meet"
                                                  viewBox="0 0 30 30"
                                             >
                                                  <g>
                                                       <path
                                                            d="M21.1,0H8.9C4,0,0,4,0,8.9v12.1C0,26,4,30,8.9,30h12.1c4.9,0,8.9-4,8.9-8.9V8.9
                                        C30,4,26,0,21.1,0z M27,21.1c0,3.3-2.7,5.9-5.9,5.9H8.9C5.7,27,3,24.3,3,21.1V8.9C3,5.7,5.7,3,8.9,3h12.1C24.3,3,27,5.7,27,8.9
                                        L27,21.1L27,21.1z"
                                                       ></path>
                                                       <path
                                                            d="M15,7.2c-4.3,0-7.8,3.5-7.8,7.8s3.5,7.8,7.8,7.8s7.8-3.5,7.8-7.8S19.3,7.2,15,7.2z M15,19.7
                                   c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7C19.7,17.6,17.6,19.7,15,19.7z"
                                                       ></path>
                                                       <circle
                                                            cx="22.8"
                                                            cy="7.3"
                                                            r="1.9"
                                                       ></circle>
                                                  </g>
                                             </svg>
                                        </a>
                                   </div>
                              </div>
                         </section>
                         <img className="blob" src={blob} alt="blob"/>
                         <img className="onda" src={onda} alt="ondaAnimation" />
                    </div>
               </>
          );
     };

     export default Navbar;
