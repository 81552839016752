import React from 'react'
import './error.css'
import {default as logo} from '../../assets/images/logo.webp'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='pageError'>
        <img src={logo} width={250} height={170}/>
        <div className='information__Error'>
            <h1>Oops!</h1>
            <h3>404 - Page not found</h3>
            <p>A página que você está procurando pode ter sido removida teve seu nome alterado ou está temporariamente indisponível.</p>
            <Link to='/home' className='btn'>Voltar ao Home</Link>
        </div>
    </div>
  )
}

export default Error