import React, { useEffect, useRef } from 'react';
//css
import './blog.css';
//Outros
import AOS from 'aos';
import gsap from 'gsap';
import Navbar from '../../components/Navbar';
import Transition from '../../components/Transition';
import Footer from '../../components/Footer';

import { default as photo1 } from '../../assets/images/blog/1.webp';
import { default as photo2 } from '../../assets/images/blog/2.webp';
import { default as photo3 } from '../../assets/images/blog/3.webp';
import { default as photo4 } from '../../assets/images/blog/4.webp';
import { default as photo5 } from '../../assets/images/blog/5.webp';
import { default as photo6 } from '../../assets/images/blog/6.webp';
import { default as photo7 } from '../../assets/images/blog/7.webp';
import { default as photo8 } from '../../assets/images/blog/8.webp';
import { default as photo9 } from '../../assets/images/blog/9.webp';

import { Link } from 'react-router-dom';
const Blog = () => {
     const blog = gsap.timeline();
     const blogImg = useRef(null);
     useEffect(() => {
          gsap.from(
               blogImg.current,
               {
                    duration: 0.4,
                    y: 200,
                    opacity: 0,
               },
               '-=3'
          );
          setTimeout(() => {
               document.querySelector('.loading').style.display = 'none';
          }, 800);
     });

     return (
          <div id="blog">
               {/* <Whatsapp /> */}
               <Transition timeline={blog} />
               <Navbar />
               <div className="inside-blog" ref={blogImg}>
                    <h1>Blog Raízes Psicologia</h1>

                    <section className="portfolio">
                         <div className="group-fotos">
                              <Link to="/blog/saude/angustia" className="fotos">
                                   <img src={photo9} alt="photo9" />
                                   <h4>5 min</h4>
                                   <div className="descricao-materia">
                                        <h3>Saúde e bem-estar</h3>
                                        <h1>
                                             Angústia: o que é, quais os
                                             sintomas e cmo lidar com ela
                                        </h1>
                                        <h5>
                                             15 de Janeiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                              </Link>
                              <Link
                                   to="/blog/empresas/diversidade-nas-empresas"
                                   className="fotos fotoMaior"
                              >
                                   <img src={photo2} alt="photo2" />
                                   <h4>3 min</h4>
                                   <div className="descricao-materia">
                                        <h3>Para empresas</h3>
                                        <h1>
                                             Diversidade nas empresas: o que é e
                                             como melhorá-la
                                        </h1>
                                        <h5>
                                             01 de Fevereiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                              </Link>
                         </div>

                         <div className="group-fotos">
                              <Link
                                   to={'/blog/saude/amor-proprio'}
                                   className="fotos fotoMaior"
                              >
                                   <img src={photo3} alt="photo3" />
                                   <h4>9 min</h4>
                                   <div className="descricao-materia">
                                        <h3>Saúde e bem-estar</h3>
                                        <h1>
                                             Como ter mais amor-próprio: 4 dicas
                                             incríveis
                                        </h1>
                                        <h5>
                                             11 de Janeiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                              </Link>
                              <Link
                                   to={'/blog/transtornos/ansiedade'}
                                   className="fotos"
                              >
                                   <img src={photo4} alt="photo4" />
                                   <h4>11 min</h4>
                                   <div className="descricao-materia">
                                        <h3>Transtornos</h3>
                                        <h1>
                                             Ansiedade: 22 sintomas do
                                             transtorno de ansiedade e TUDO o
                                             que você precisa saber sobre o
                                             assunto
                                        </h1>
                                        <h5>
                                             06 de Fevereiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                              </Link>
                         </div>

                         <Link
                              to={'/blog/saude/como-controlar-os-ciumes'}
                              className="group-fotos miniatura"
                         >
                              <div className="fotos">
                                   <img src={photo5} alt="photo5" />
                              </div>
                              <div className="conteudo">
                                   <div className="descricao-materia">
                                        <h3>Saúde e bem-estar</h3>
                                        <h1>
                                             Como controlar os ciúmes: 13 dicas
                                             de psicólogos
                                        </h1>
                                        <h5>
                                             07 de Fevereiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                                   <p className="btn">Ler</p>
                              </div>
                         </Link>
                         <Link
                              to={'/blog/especialista/psicoterapia'}
                              className="group-fotos miniatura"
                         >
                              <div className="fotos">
                                   <img src={photo6} alt="photo6" />
                              </div>
                              <div className="conteudo">
                                   <div className="descricao-materia">
                                        <h3>Palavra de Especialista</h3>
                                        <h1>
                                             Psicoterapias: desconstruindo
                                             preconceitos, conquistando novas
                                             possibilidades de vida.
                                        </h1>
                                        <h5>
                                             26 de Janeiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                                   <p className="btn">Ler</p>
                              </div>
                         </Link>
                         <Link
                              to={'/blog/saude/como-melhorar-a-autoestima'}
                              className="group-fotos miniatura"
                         >
                              <div className="fotos">
                                   <img src={photo7} alt="photo7" />
                              </div>
                              <div className="conteudo">
                                   <div className="descricao-materia">
                                        <h3>Saúde e bem-estar</h3>
                                        <h1>
                                             Autoestima: o que é e como melhorar
                                             a sua{' '}
                                        </h1>
                                        <h5>
                                             14 de Janeiro |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                                   <p className="btn">Ler</p>
                              </div>
                         </Link>
                         <Link
                              to={'/blog/saude/o-que-e-depressao'}
                              className="group-fotos miniatura"
                         >
                              <div className="fotos">
                                   <img src={photo8} alt="photo8"/>
                              </div>
                              <div className="conteudo">
                                   <div className="descricao-materia">
                                        <h3>Transtornos</h3>
                                        <h1>Afinal, o que é depressão?</h1>
                                        <h5>
                                             22 de setembro 2021 |{' '}
                                             <span>por Raízes Psicologia</span>
                                        </h5>
                                   </div>
                                   <p className="btn">Ler</p>
                              </div>
                         </Link>
                    </section>

                    <Footer />
               </div>
          </div>
     );
};

export default Blog;
