import React, { useRef, useEffect, useState } from 'react';
import Transition from '../../components/Transition';
import './faleconosco.css';
import gsap from 'gsap';
import { Link, useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { default as close } from '../../assets/images/svg/close.svg';
import { default as logo } from '../../assets/images/logo.webp';
import { default as loading } from '../../assets/images/loading.gif';
import { FcOk } from 'react-icons/fc';
import AOS from 'aos';
import api from '../../components/api';

const FaleConosco = () => {
     const history = useHistory();
     const fale = gsap.timeline();
     const faleImg = useRef(null);
     useEffect(() => {
          fale.from(
               faleImg.current,
               {
                    duration: 0.5,
                    y: -200,
                    opacity: 0,
               },
               '-=3'
          );
     });

     useEffect(() => {
          AOS.init();
          AOS.refresh();
          setTimeout(() => {
               document.querySelector('.loading').style.display = 'none';
          }, 800);
     }, []);

     const [nome, setNome] = useState('');
     const [tel, setTel] = useState('');
     const [email, setEmail] = useState('');
     const [mensagem, setMensagem] = useState('');

     const sendEmail = () => {
          document.querySelector('.container-message').classList.add('active');
          document.querySelector('.loadingSend').classList.add('active');
          api.post('/send-email', { nome, email, tel, mensagem }).then(
               () => {
                    document
                         .querySelector('.loadingSend')
                         .classList.remove('active');

                    document
                         .querySelector('.send-message')
                         .classList.add('active');
               }
          );
     };

     return (
          <div className="faleConosco">
               <Transition timeline={fale} />
               <div className="container-message">
                    <div className="loadingSend">
                         <img src={loading} width={120} />
                    </div>
                    <div className="send-message">
                         <div className="send-success">
                              <FcOk size={50} />
                              <h2>Mensagem enviada com sucesso!</h2>
                         </div>
                         <Link
                              to="/home"
                              className="btn"
                              onClick={() => {
                                   document
                                        .querySelector('.container-message')
                                        .classList.remove('active');
                                   document
                                        .querySelector('.send-message')
                                        .classList.remove('active');
                              }}
                         >
                              Entendido :)
                         </Link>
                    </div>
               </div>
               <div className="container-faleConosco" ref={faleImg}>
                    <img
                         src={close}
                         alt="close-btn"
                         className="close-btn"
                         onClick={() => {
                              document
                                   .querySelector('.barra-horizontal')
                                   .classList.add('desmontar');
                              setTimeout(() => {
                                   document
                                        .querySelector('.barra-vertical')
                                        .classList.add('desmontar');
                              }, 500);
                              setTimeout(() => {
                                   document
                                        .querySelector('.faleConosco')
                                        .classList.add('sair');
                              }, 1000);

                              setTimeout(() => {
                                   history.push('/home');
                              }, 1412);
                         }}
                    />
                    <div
                         className="containerLogo"
                         data-aos="fade-down"
                         data-aos-duration="1000"
                         data-aos-delay="2300"
                    >
                         <img
                              className="logo"
                              alt="logoFaleConosco"
                              src={logo}
                              width={280}
                         />
                    </div>
                    <div
                         className="content-faleConosco"
                         data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-delay="1800"
                    >
                         <div className="alinhamento">
                              <section className="text-faleConosco">
                                   <h4>Contato</h4>
                                   <h2>
                                        Por favor, sinta-se à vontade para
                                        deixar uma mensagem.
                                   </h2>
                                   <p>
                                        Entraremos em contato com você o mais
                                        breve possível.
                                   </p>
                              </section>
                              <form
                                   className="form-faleConosco"
                                   onSubmit={(e) => {
                                        e.preventDefault();
                                        sendEmail();
                                   }}
                              >
                                   <div className="label-input">
                                        <input
                                             id="nome"
                                             type="text"
                                             onChange={(e) =>
                                                  setNome(e.target.value)
                                             }
                                             value={nome}
                                             required
                                        />
                                        <label htmlFor="nome">Nome</label>
                                   </div>

                                   <div className="label-input">
                                        <input
                                             id="email"
                                             type="email"
                                             onChange={(e) =>
                                                  setEmail(e.target.value)
                                             }
                                             value={email}
                                             required
                                        />
                                        <label htmlFor="email">Email</label>
                                   </div>
                                   <div className="label-input">
                                        <input
                                             id="telefone"
                                             type="text"
                                             onChange={(e) =>
                                                  setTel(e.target.value)
                                             }
                                             value={tel}
                                             required
                                        />
                                        <label htmlFor="telefone">Telefone</label>
                                   </div>
                                   <div className="label-input">
                                        <input
                                             id="mensagem"
                                             type="text"
                                             onChange={(e) =>
                                                  setMensagem(e.target.value)
                                             }
                                             value={mensagem}
                                             required
                                        />
                                        <label htmlFor="mensagem">Mensagem</label>
                                   </div>
                                   <div className="submit">
                                        <button
                                             className="btn enviar"
                                             type="submit"
                                        >
                                             Enviar
                                        </button>
                                        <button className="arrow-enviar">
                                             <svg
                                                  width="17"
                                                  height="33"
                                                  viewBox="0 0 17 33"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                             >
                                                  <path
                                                       d="M14.654 15.1092L14.2947 15.4569L14.654 15.1092ZM14.654 17.8908L15.0133 18.2386L14.654 17.8908ZM15.6084 16.5H15.1084H15.6084ZM0.640699 1.34771L14.2947 15.4569L15.0133 14.7614L1.3593 0.652289L0.640699 1.34771ZM14.2947 17.5431L0.640699 31.6523L1.3593 32.3477L15.0133 18.2386L14.2947 17.5431ZM14.2947 15.4569C14.6225 15.7956 14.8331 16.0147 14.9679 16.1968C15.094 16.3673 15.1084 16.447 15.1084 16.5H16.1084C16.1084 16.1484 15.9637 15.8614 15.7717 15.602C15.5883 15.3542 15.3218 15.0802 15.0133 14.7614L14.2947 15.4569ZM15.0133 18.2386C15.3218 17.9198 15.5883 17.6458 15.7717 17.398C15.9637 17.1386 16.1084 16.8516 16.1084 16.5H15.1084C15.1084 16.553 15.094 16.6327 14.9679 16.8032C14.8331 16.9853 14.6225 17.2044 14.2947 17.5431L15.0133 18.2386Z"
                                                       fill="#F3E0CA"
                                                  ></path>
                                             </svg>
                                        </button>
                                   </div>
                              </form>
                         </div>
                    </div>
               </div>

               <div className="barra-vertical"></div>
               <div className="barra-horizontal"></div>
          </div>
     );
};

export default FaleConosco;
