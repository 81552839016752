import React from 'react';
//PAGES
import Home from './pages/Home';
import FaleConosco from './pages/FaleConosco';
import Estrutura from './pages/Estrutura';
import VideoConferencia from './pages/VideoConferencia';
import Blog from './pages/Blog';
import {
     AmorProprio,
     Angustia,
     Autoestima,
     Ciumes,
} from './pages/Blog/Artigos/Saude';
//CSS
import './assets/css/style.css';
//REACT ROUTER DOM
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Preload from './components/Preload';
import Ansiedade from './pages/Blog/Artigos/Transtornos/Ansiedade';
import Diversidade from './pages/Blog/Artigos/Empresas/Diversidade';
import Psicoterapia from './pages/Blog/Artigos/Especialista/Psicoterapia';
import { Depressao } from './pages/Blog/Artigos/Transtornos';
import Whatsapp from './components/Whatsapp';
import ScrollTop from './components/ScrollTop';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Error from './pages/Error';

function App() {
     return (
          <BrowserRouter>
               <Whatsapp />
               <ScrollTop />
               <Switch>
                    <Route exact path={'/'}>
                         <>
                              <Preload />
                              <Home />
                         </>
                    </Route>

                    <Route exact path={'/home'} component={Home} />
                    <Route
                         exact
                         path={'/fale-conosco'}
                         component={FaleConosco}
                    />
                    <Route exact path={'/estrutura'} component={Estrutura} />
                    <Route
                         exact
                         path={'/eficacia'}
                         component={VideoConferencia}
                    />
                    <Route exact path="/blog" component={Blog} />

                    {/* MATERIAS DO BLOG */}
                    <Route
                         exact
                         path="/blog/saude/angustia"
                         component={Angustia}
                    />
                    <Route
                         exact
                         path="/blog/transtornos/ansiedade"
                         component={Ansiedade}
                    />
                    <Route
                         exact
                         path="/blog/empresas/diversidade-nas-empresas"
                         component={Diversidade}
                    />
                    <Route
                         exact
                         path="/blog/saude/amor-proprio"
                         component={AmorProprio}
                    />
                    <Route
                         exact
                         path="/blog/saude/como-controlar-os-ciumes"
                         component={Ciumes}
                    />
                    <Route
                         exact
                         path="/blog/especialista/psicoterapia"
                         component={Psicoterapia}
                    />
                    <Route
                         exact
                         path="/blog/saude/como-melhorar-a-autoestima"
                         component={Autoestima}
                    />
                    <Route
                         exact
                         path="/blog/saude/o-que-e-depressao"
                         component={Depressao}
                    />
                    <Route path={'/*'} component={Error} />
                    
               </Switch>
          </BrowserRouter>
     );
}

export default App;
