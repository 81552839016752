import React from 'react';
import $ from 'jquery';
import './wpp.css';

const Whatsapp = () => {
     $(document).ready(function () {
          const wpp = document.querySelector('.circle-wpp');
          $(window).scroll(function () {
               if ($(this).scrollTop() > 300) {
                    wpp.style.display = 'flex';
               } else {
                    wpp.style.display = 'none';
               }

               if (document.querySelector('#artigo')) {
                    document.querySelector('.circle-wpp').style.display =
                         'none';
                    document.querySelector('.scrollTop').style.display = 'none';
               }
          });
     });

     return (
          <div
               className="circle-wpp"
               onClick={() => {
                    window.open(
                         'https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia',
                         '_target'
                    );
               }}
          ></div>
     );
};

export default Whatsapp;
