import React, { useEffect, useRef } from 'react';
import { default as photo1 } from '../../Banners/psicoterapia.webp';
import { default as logo } from '../../Banners/logo.webp';
import { default as autor } from '../../Banners/leticia.webp';

import Footer from '../../../../components/Footer';
import { Link } from 'react-router-dom';
import $ from 'jquery';
const Psicoterapia = () => {
     const progressBar = useRef(0);
     const main = useRef(0);

     const scrollProgressBar = () => {
          if (progressBar.current) {
               progressBar.current.style.display = 'block';
          }
          if (main.current) {
               let scrollDistance = -main.current.getBoundingClientRect().top;

               let progressPercentage =
                    (scrollDistance /
                         (main.current.getBoundingClientRect().height -
                              document.documentElement.clientHeight)) *
                    100;
               let val = Math.floor(progressPercentage);
               if (progressBar.current) {
                    progressBar.current.style.width = val + '%';
               }
               if (val < 0) {
                    progressBar.style.width = '0%';
               }
          }
     };
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
          window.addEventListener('scroll', scrollProgressBar);
     });
     return (
          <>
               <main id="artigo" ref={main}>
                    <div className="navArtigo">
                         <img
                              className="logoArtigo"
                              src={logo}
                              alt="logo"
                              width={130}
                         />
                    </div>
                    <h1 className="title">
                         Psicoterapias: desconstruindo preconceitos,
                         conquistando novas possibilidades de vida.
                    </h1>
                    <h3 className="date">
                         26 de Janeiro | por Raízes Psicologia
                    </h3>
                    <h3 className="path">
                         <Link to="/home">Home </Link>
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <Link to="/blog">Blog </Link>{' '}
                         <svg
                              width="7.875"
                              height="13.405"
                              viewBox="0 0 7.875 13.405"
                         >
                              <defs></defs>
                              <g transform="translate(-101.478)">
                                   <g transform="translate(101.478)">
                                        <path
                                             fill="#989898"
                                             d="M109.14,6.18,103.174.214a.735.735,0,0,0-1.037,0L101.7.653a.734.734,0,0,0,0,1.037L106.707,6.7l-5.015,5.015a.735.735,0,0,0,0,1.037l.439.439a.735.735,0,0,0,1.037,0L109.14,7.22a.74.74,0,0,0,0-1.041Z"
                                             transform="translate(-101.478)"
                                        ></path>
                                   </g>
                              </g>
                         </svg>{' '}
                         <p>
                              Psicoterapias: desconstruindo preconceitos,
                              conquistando novas possibilidades de vida.
                         </p>
                    </h3>
                    <div className="banner">
                         <img src={photo1} alt="banner-angustia" />
                    </div>
                    <div className="progressBar" ref={progressBar}></div>
                    <article className="textos-materia">
                         <p className="m-15">
                              Houve um tempo em que fazer{' '}
                              <strong>psicoterapia</strong> relacionava-se à
                              presença de um grave distúrbio mental. Uma
                              atividade destinada a pessoas com “problemas”
                              psicológicos e psiquiátricos graves.
                         </p>
                         <p className="m-15">
                              Problemas estes que foram sendo excluídos e
                              rejeitados pelo contexto social.
                         </p>

                         <p className="m-15">
                              As pessoas que conviviam com o sofrimento mental,
                              eram reservados lugares sombrios e reclusos dentro
                              da sociedade. Isso posto, vale a pena fazermos
                              duas perguntas:
                         </p>

                         <p className="m-15">
                              De que maneira essa ideia limitadora foi sendo
                              construída ao longo do processo civilizatório?
                         </p>

                         <p className="m-15">
                              Como os processos psicoterapêuticos, nos dias
                              atuais, passaram a ser compreendidos e
                              incorporados pela sociedade?
                         </p>
                         <p className="m-15">
                              A psicoterapia, tal como a conhecemos hoje, tem
                              sua origem no final do século XIX. Podemos dizer
                              que até esse período os fenômenos mentais foram
                              compreendidos sem um rigor metodológico de
                              investigação científica.
                         </p>
                         <h2 className="subtitle">
                              Uma breve história da psicoterapia
                         </h2>

                         <p className="m-15">
                              De maneira sucinta, podemos encadear o seguinte
                              traçado histórico relacionado à saúde mental:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Nos grupos tribais mais remotos, os
                                   transtornos mentais eram associados às forças
                                   sobrenaturais, encarados como um fenômeno de
                                   possessão. Rituais xamânicos, magias e poções
                                   dentre outros, faziam parte das práticas em
                                   que buscava-se “tratar” os desvios da mente.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Já na Grécia, a produção filosófica e os
                                   primórdios da ciência médica sustentam as
                                   raízes do que compreendemos hoje como
                                   processos psicoterapêuticos. Além disso, as
                                   produções literárias, o teatro, a mitologia,
                                   abarcavam as dimensões psicológicas e os
                                   impasses humanos, reconhecendo e
                                   compreendendo o dinamismo da vida mental.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Na Idade Média, por sua vez, as ideias
                                   religiosas contextualizavam a compreensão da
                                   vida mental. As forças do mal e a dimensão
                                   demoníaca davam sentido aos desvios da
                                   psique.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   No século XVI e XVII surgem os primeiros
                                   hospitais psiquiátricos com o intuito de
                                   contenção.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   No século XVIII, influenciados pelas ideias
                                   do iluminismo, observa-se um processo de
                                   humanização na compreensão das doenças
                                   mentais.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Na segunda metade do século XIX, podemos
                                   considerar como sendo o início do que
                                   compreendemos hoje como processos
                                   psicoterapêuticos. O médico francês Charcot
                                   (1825-1893) contribui com suas pesquisas para
                                   o entendimento de algumas doenças do cérebro.
                                   Em seus estudos, Charcot observa um efeito
                                   positivo da hipnose nos transtornos mentais.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   No final do século XIX e início do século XX,
                                   vários médicos e cientistas se debruçam sobre
                                   os fenômenos mentais, quer seja por meio dos
                                   sonhos, da hipnose ou da associação livre,
                                   dentre outros. Destaca-se nesse período
                                   Sigmund Freud, que por meio de seus estudos e
                                   pesquisas, concebe a teoria psicanalítica que
                                   considera o inconsciente como uma base para
                                   os processos mentais.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   No início do século XX ocorre uma
                                   efervescência de pesquisas e estudos sobre a
                                   psicologia. A vida mental passou a ser
                                   considerada como um objeto de estudo. Vários
                                   teóricos emergem como estudiosos da psique:
                                   Jung, Skinner, Moreno, Reich, dentre outros.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   Na metade do século XX observa-se o
                                   desenvolvimento de pesquisas e estudos
                                   clínicos envolvendo a saúde mental. Em 1948 a
                                   OMS (Organização Mundial de Saúde) incluiu
                                   pela primeira vez uma sessão destinada aos
                                   Transtornos Mentais. Já a primeira edição do
                                   Manual Diagnóstico e Estatístico de
                                   Transtornos Mentais (DSM) foi publicada pela
                                   Associação Psiquiátrica Americana (APA) em
                                   1953.
                              </li>
                         </ul>

                         <h2 className="subtitle">
                              O que podemos observar sobre a Saúde Mental na
                              atualidade
                         </h2>
                         <p className="m-15">
                              Setembro amarelo, saúde mental no trabalho,{' '}
                              <strong>
                                   crises de ansiedade, síndrome do pânico,
                                   burnout, depressão
                              </strong>
                              , transtornos das mais diversas ordens, são
                              conceitos que passaram a fazer parte do
                              vocabulário do nosso dia a dia.
                         </p>

                         <p className="m-15">
                              Diversas séries e filmes – Atypical, The Alienist,
                              This is us, Sessão de terapia, Sense8, Please Like
                              Me, dentre tantas outras – trazem à tona a
                              complexidade humana, o universo psicológico e as
                              psicoterapias como tomando parte importante na
                              vida contemporânea.
                         </p>

                         <h2 className="subtitle">
                              O que essa tendência nos aponta?
                         </h2>
                         <p className="m-15">
                              Evidenciam de maneira mais significativa a procura
                              humana por um significado da própria existência,
                              por se conhecer de maneira mais profunda, por uma
                              necessidade de conseguir se apaziguar consigo
                              mesmo e com os outros, redimensionar traumas,
                              perseguir suas inspirações mais genuínas e
                              autênticas, enfrentar a solidão dos preconceitos,
                              entre outras tantas temáticas abordadas. Conferem
                              um reconhecimento e uma afirmação da importância
                              da vida mental.
                         </p>

                         <p className="m-15">
                              Nesse sentido, podemos destacar alguns aspectos
                              importantes para redimensionarmos a saúde mental
                              na contemporaneidade:
                         </p>

                         <ul>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        Redução do preconceito em relação aos
                                        processos psicoterapêuticos
                                   </strong>{' '}
                                   – Como apresentado acima, por muito tempo, as
                                   psicoterapias foram compreendidas como
                                   tratamentos específicos para as doenças
                                   mentais. Procuravam por profissionais da área
                                   da psicologia, pessoas que manifestavam
                                   graves distúrbios de comportamentos. Esta era
                                   a esfera de atuação reservada aos
                                   profissionais da{' '}
                                   <strong>saúde mental</strong>. O que
                                   observamos nos dias de hoje é uma preocupação
                                   em relação aos{' '}
                                   <strong>estados emocionais</strong> e não
                                   somente às doenças mentais. Identificamos
                                   também que existe um ambiente de liberdade
                                   para que pessoas com diferentes necessidades
                                   possam procurar apoio psicoterapêutico. A
                                   procura não é mais identificada como
                                   “doença”.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        {' '}
                                        Ampliação da atuação da psicologia –
                                   </strong>
                                   Observa-se um crescimento não só da demanda
                                   pelas psicoterapias em geral, como também
                                   pelo trabalho psicológico em diversas
                                   atividades profissionais. Cuidar do ambiente
                                   psicológico nas gestões empresariais,
                                   instituições escolares, de saúde, dentre
                                   outras, tornou-se um valor reconhecido e
                                   difundido.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        Democratização do acesso à saúde mental
                                        –
                                   </strong>
                                   Como decorrência, observamos que mais
                                   pessoas, de diferentes origens sociais e
                                   culturais, consideram nos dias de hoje a
                                   possibilidade de recorrerem às psicoterapias
                                   como forma de estabelecerem diálogos
                                   significativos para os impasses de suas
                                   vidas.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        Ampliação do acesso aos profissionais de
                                        saúde mental –
                                   </strong>{' '}
                                   Em 2018, o Conselho Federal de Psicologia
                                   engloba as tecnologias de informação e
                                   comunicação como ambiente de trabalho para o
                                   exercício profissional da categoria.
                                   Observa-se um crescimento dos atendimentos
                                   online, transportando metodologias e técnicas
                                   para o ambiente virtual.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        Educação emocional / Habilidades
                                        socioemocionais –
                                   </strong>{' '}
                                   São objetivos que passam a incorporar os
                                   projetos escolares, agregando valor à saúde
                                   mental.
                              </li>
                              <li className="m-15 ">
                                   <p className="circle"></p>
                                   <strong>
                                        As plataformas digitais de atendimento
                                        online –{' '}
                                   </strong>
                                   Favorecem e disponibilizam o acesso daqueles
                                   que buscam por um atendimento
                                   psicoterapêutico, ampliando a democratização
                                   dos atendimentos psicológicos.
                              </li>
                         </ul>
                         <p className="m-15">
                              O mundo contemporâneo favoreceu o crescimento de
                              uma visibilidade positiva sobre a saúde mental,
                              reconhecendo os transtornos, favorecendo o
                              cuidado, desenvolvendo ambientes mais saudáveis e
                              favoráveis ao desenvolvimento humano.
                         </p>
                         <p className="m-15">
                              Frente a esse contexto, as terapias ganham espaço
                              e credibilidade, na ciência e na humanidade.
                         </p>

                         <h2 className="subtitle">Eu posso te ajudar</h2>

                         <p className="m-15">
                              Eu posso te ajudar a entender os seus sentimentos
                              e emoções. Você pode conversar comigo sobre{' '}
                              <strong>ansiedade</strong>, insônia,
                              procrastinação, <strong>autoestima</strong> e
                              conflitos familiares.
                         </p>
                         <p className="m-15">
                              Vem comigo nessa jornada rumo ao autoconhecimento!
                         </p>
                         <h2 className="subtitle2">Fontes:</h2>
                         <a
                              href="https://siteantigo.portaleducacao.com.br/conteudo/artigos/psicologia/historia-da-psicoterapia/16769"
                              className="m-15 fonte"
                         >
                              https://siteantigo.portaleducacao.com.br/conteudo/artigos/psicologia/historia-da-psicoterapia/16769
                         </a>
                         <a
                              href="https://doi.org/10.5007/1807-1384.2019v16n1p73"
                              className="m-15 fonte"
                         >
                              https://doi.org/10.5007/1807-1384.2019v16n1p73
                         </a>

                         <div className="divisor"></div>
                         <div className="autor">
                              <img src={autor} alt="autora" />
                              <div className="sobre-autor">
                                   <h3>
                                        Publicado por:{' '}
                                        <span>Letícia Medeiros</span>
                                   </h3>
                                   <h5>
                                        Letícia Medeiros é psicóloga com
                                        formação em Terapia Cognitivo
                                        Comportamental, mestre em Psicologia do
                                        desenvolvimento humano e processos
                                        socioeducativos.
                                   </h5>
                              </div>
                         </div>
                    </article>
               </main>
               <section className="agendar-agora">
                    <h3>Quer começar a fazer terapia agora mesmo?</h3>
                    <a
                         target={'_blank'}
                         href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                         className="btn btn-apresentation"
                    >
                         Agende uma Sessão
                    </a>
                    <span
                         onClick={() => {
                              document.querySelector(
                                   '.agendar-agora'
                              ).style.display = 'none';
                         }}
                    >
                         &#x2715;
                    </span>
               </section>
               <Footer />
          </>
     );
};

export default Psicoterapia;
