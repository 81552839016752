import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Navbar from '../../components/Navbar';
import './home.css';

import Logo from '../../assets/images/logo.webp';
import { default as woman } from '../../assets/images/slider-preview/woman.webp';
import { default as meditacao } from '../../assets/images/slider-preview/meditacao.webp';
import { default as motivacao } from '../../assets/images/slider-preview/motivacao.webp';
import { default as angustia } from '../Blog/Banners/2.webp';
import { default as amorProprio } from '../Blog/Banners/3.webp';
import { default as warning } from '../../assets/images/warning.webp';
import { default as tristeza } from '../../assets/images/slider-preview/tristeza.webp';
import { default as diversidade } from '../../assets/images/slider-preview/diversidade.webp';
// depoimentos
import { default as dep1 } from '../../assets/images/depoimento/and.webp';
import { default as dep2 } from '../../assets/images/depoimento/ju.webp';
import { default as dep3 } from '../../assets/images/depoimento/anoni.webp';
//passos
import { default as passo1 } from '../../assets/images/passos/1.svg';
import { default as passo2 } from '../../assets/images/passos/2.svg';
import { default as passo3 } from '../../assets/images/passos/3.svg';
import { default as passo4 } from '../../assets/images/passos/4.svg';

import { default as coracao } from '../../assets/images/svg/icon-coracao.svg';
import { default as onda } from '../../assets/images/svg/svgOnda.svg';

import Slider from 'react-slick';
import Preload from '../../components/Preload';
import $ from 'jquery';
import Footer from '../../components/Footer';
import AOS from 'aos';
import Transition from '../../components/Transition';
import gsap from 'gsap';

const depoimentos = () => {
     const settings = {
          dots: false,
          infinite: true,
          fade: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 5000,
     };
     return (
          <div className="slide_depoimentos">
               <Slider {...settings}>
                    <div className="depoimento">
                         <div className="perfil ">
                              <img src={dep1} width={150} alt="dep1" />
                         </div>
                         <p className="text-depoimento">
                              Amei a primeira consulta!
                         </p>
                         <div>
                              <h6>Anderson R.</h6>
                              <div className="stars">
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                              </div>
                         </div>
                         <span className="date-dep">18/01/2022 - 19:52</span>
                    </div>
                    <div className="depoimento">
                         <div className="perfil ">
                              <img src={dep2} width={150} alt="dep2" />
                         </div>
                         <p className="text-depoimento">
                              Sensacional! Fundamental!{' '}
                              <strong>
                                   Mudou meu jeito de encarar a vida.
                              </strong>{' '}
                              Me ajudou demais no momento em que eu mais
                              precisava a superar as situações difíceis e os
                              problemas, com muito carinho e amizade.
                         </p>
                         <div>
                              <h6>Julia G.</h6>
                              <div className="stars">
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                              </div>
                         </div>
                         <span className="date-dep">25/12/2021 - 10:02</span>
                    </div>
                    <div className="depoimento">
                         <div className="perfil ">
                              <img src={dep3} width={150} alt="dep3" />
                         </div>
                         <p className="text-depoimento">
                              Profissional extremamente simpática e passa uma
                              segurança que nos deixa muito confortáveis ao
                              falar e compartilhar o nosso íntimo.
                         </p>

                         <div>
                              <h6>Leticia S.</h6>
                              <div className="stars">
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                                   <svg
                                        height="22px"
                                        width="24px"
                                        viewBox="0 0 248.1 270.2"
                                   >
                                        <path
                                             fill="#E06A59"
                                             d="M121.1,19.5c-7.4,3.2-11.9,11.4-22,40.6c-9.6,27.6-11.7,31.4-18.3,32.6c-1.8,0.4-17.2,0.7-34,0.8 c-27.6,0.1-31.1,0.3-35,2c-8.7,3.8-11.5,11.2-7.3,19.4c2.8,5.4,13.5,15.2,31.2,28.5c8.6,6.4,17.6,13.8,19.9,16.3 c4.2,4.6,4.3,4.7,3.7,10.4c-0.4,3.2-3.8,14.7-7.6,25.6c-8.5,24.6-8.7,25.4-10.1,33.3c-2.2,12.7,2.4,20.1,12.5,20.1 c7.2,0,17-5.1,37.5-19.4c16.9-11.9,26.5-17.6,29.7-17.6c3,0,10.4,4.2,19.6,11.2c25.6,19.4,30.2,22.7,35.4,25.3 c3.5,1.8,7.7,3,11.3,3.3c4.9,0.4,6,0.1,8.7-2.2c2.1-1.8,3.5-4.2,4.3-7.5c1.9-7.9,0.3-15.4-10.4-50.6c-2.1-6.6-4-14.8-4.3-18.3 l-0.6-6.3l4.9-4.7c2.8-2.6,12.7-10,22.2-16.5c30.2-20.7,37.2-29.6,31.2-39.4c-4.4-7.4-12.3-9-48.1-10.2c-31.8-1-32.3-1.1-36.1-9.7 c-1.5-3.3-5.4-15.1-8.8-26.3c-8.8-29.1-13-37.5-20.7-40.7C126,17.8,124.9,17.8,121.1,19.5z"
                                        ></path>
                                   </svg>
                              </div>
                         </div>
                         <span className="date-dep">06/08/2021 - 14:24</span>
                    </div>
               </Slider>
          </div>
     );
};

const Home = () => {
     const history = useHistory();

     const Center = () => {
          const settings = {
               dots: true,
               infinite: true,
               slidesToShow: 3,
               slidesToScroll: 1,
               autoplay: true,
               speed: 5000,
               autoplaySpeed: 1500,
               cssEase: 'linear',
          };

          return (
               <div className="container-cards desktop">
                    <Slider className="slider" {...settings}>
                         <div
                              onClick={() => {
                                   history.push(
                                        'blog/saude/como-melhorar-a-autoestima'
                                   );
                              }}
                              className="card tilt-box-wrap"
                              style={{
                                   pointerEvents: 'none !important',
                                   width: 250,
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <div className="tilt-box">
                                   <img
                                        src={woman}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '60%',
                                             borderRadius: '15px',
                                             pointerEvents: 'none !important',
                                        }}
                                        alt="img-woman"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>10 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Autoestima: o que é e como melhorar
                                             a sua
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>
                         <div
                              className="card tilt-box-wrap"
                              onClick={() => {
                                   history.push(
                                        'blog/empresas/diversidade-nas-empresas'
                                   );
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>

                              <div className="tilt-box">
                                   <img
                                        src={meditacao}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '25%',
                                             borderRadius: '15px',
                                        }}
                                        alt="meditacao"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>3 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Diversidade nas empresas: o que é e
                                             como melhorá-la
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>

                         <div
                              className="card tilt-box-wrap"
                              onClick={() => {
                                   history.push('blog/saude/angustia');
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>

                              <div className="tilt-box">
                                   <img
                                        src={angustia}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '80%',
                                             borderRadius: '15px',
                                        }}
                                        alt="img-angustia"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>5 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Angústia: o que é, quais os
                                             sintomas e como lidar com ela
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>
                         <div
                              className="card tilt-box-wrap"
                              onClick={() => {
                                   history.push('/blog/saude/amor-proprio');
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>

                              <div className="tilt-box">
                                   <img
                                        src={amorProprio}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '30%',
                                             borderRadius: '15px',
                                        }}
                                        alt="img-amorProprio"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>2 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Como ter mais amor-próprio: 4 dicas
                                             incríveis
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>
                    </Slider>
               </div>
          );
     };

     const CenterMobile = () => {
          const settings = {
               dots: true,
               infinite: true,
               speed: 500,
               slidesToShow: 1,
               slidesToScroll: 1,
          };

          return (
               <div className="container-cards mobile">
                    <Slider className="slider" {...settings}>
                         <div
                              onClick={() => {
                                   history.push('/blog/saude/amor-proprio');
                              }}
                              className="card tilt-box-wrap"
                              style={{
                                   pointerEvents: 'none !important',
                                   width: 250,
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <div className="tilt-box">
                                   <img
                                        src={woman}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '60%',
                                             borderRadius: '15px',
                                             pointerEvents: 'none !important',
                                        }}
                                        alt="img-woman"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>9 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Como ter mais amor-próprio: 4 dicas
                                             incríveis
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>
                         <div
                              className="card tilt-box-wrap"
                              onClick={() => {
                                   history.push(
                                        '/blog/empresas/diversidade-nas-empresas'
                                   );
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>

                              <div className="tilt-box">
                                   <img
                                        src={diversidade}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '45%',
                                             borderRadius: '15px',
                                        }}
                                        alt="img-diversidade"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>3 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Diversidade nas empresas: o que é e
                                             como melhorá-la
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>

                         <div
                              className="card tilt-box-wrap"
                              onClick={() => {
                                   history.push('/blog/saude/angustia');
                              }}
                         >
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>
                              <span className="t_over"></span>

                              <div className="tilt-box">
                                   <img
                                        src={tristeza}
                                        style={{
                                             width: '99.5%',
                                             objectFit: 'cover',
                                             height: '425px',
                                             objectPosition: '60%',
                                             borderRadius: '15px',
                                        }}
                                        alt="img-tristeza"
                                   />
                                   <div className="content-card">
                                        <div className="time-card">
                                             <span>5 min de leitura</span>
                                        </div>
                                        <p className="title-card">
                                             Angústia: o que é, quais sintomas e
                                             como lidar com ela
                                        </p>

                                        <span>Leia mais...</span>
                                   </div>
                              </div>
                         </div>
                    </Slider>
               </div>
          );
     };
     // const [slideIndex, setSlideIndex] = useState(1);
     const home = gsap.timeline();
     const homeImg = useRef(null);
     useEffect(() => {
          $(document).ready(() => {
               $('html, body').animate({ scrollTop: 0 }, 600);
          });
     }, []);

     useEffect(() => {
          AOS.init();
          AOS.refresh();
     }, []);

     useEffect(() => {
          home.from(
               homeImg.current,
               {
                    duration: 0.6,
                    x: -200,
                    opacity: 0,
               },
               '-=3'
          );
          setTimeout(() => {
               if (document.querySelector('.loading')) {
                    document.querySelector('.loading').style.display = 'none';
               }
          }, 800);
     });

     return (
          <main className="pageHome">
               {/* <Whatsapp /> */}

               {history.location.pathname === '/' ? (
                    <>
                         <Navbar />
                         <div className="apresentation">
                              <img
                                   className="onda"
                                   src={onda}
                                   alt="img-onda-animation"
                              />
                              <div
                                   className="text-apresentation"
                                   data-aos="fade-up-right"
                                   data-aos-duration="1500"
                                   data-aos-delay="5300"
                              >
                                   <div>
                                        <h1 className="text-destaque">
                                             Saúde mental,{' '}
                                        </h1>
                                        <h1>é crescimento pessoal</h1>
                                   </div>
                                   <div>
                                        <p>
                                             <strong>
                                                  Impulsionamos o crescimento
                                                  pessoal e profissional.
                                             </strong>
                                        </p>
                                        <p>
                                             Do bem-estar ao pico de desempenho,
                                             acelere a mudança que quer ver em
                                             você.
                                        </p>
                                   </div>
                                   <a
                                        target={'_blank'}
                                        href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                                        className="btn btn-apresentation"
                                   >
                                        Agende uma conversa conosco
                                   </a>
                              </div>
                              <img
                                   className="imageApresentation"
                                   src={Logo}
                                   width={325}
                                   data-aos="fade-down"
                                   data-aos-duration="1500"
                                   data-aos-delay="5300"
                                   alt="logo-imageApresentation"
                              />
                         </div>
                    </>
               ) : (
                    <>
                         <Transition timeline={home} />
                         <Navbar />
                         <div className="apresentation" ref={homeImg}>
                              <img
                                   className="onda"
                                   src={onda}
                                   alt="animationOnda"
                              />
                              <div
                                   className="text-apresentation"
                                   data-aos="fade-up-right"
                                   data-aos-duration="1500"
                                   data-aos-delay="1000"
                              >
                                   <div>
                                        <h1 className="text-destaque">
                                             Saúde mental,{' '}
                                        </h1>
                                        <h1>é crescimento pessoal</h1>
                                   </div>
                                   <div>
                                        <p>
                                             <strong>
                                                  Impulsionamos o crescimento
                                                  pessoal e profissional.
                                             </strong>
                                        </p>
                                        <p>
                                             Do bem-estar ao pico de desempenho,
                                             acelere a mudança que quer ver em
                                             você.
                                        </p>
                                   </div>
                                   <a
                                        target={'_blank'}
                                        href='https://api.whatsapp.com/send?phone=+5532999944236&text=Olá, tudo bem? Vim pelo site Raízes psicologia'
                                        className="btn btn-apresentation"
                                   >
                                        Agende uma conversa conosco
                                   </a>
                              </div>
                              <img
                                   className="imageApresentation"
                                   src={Logo}
                                   width={325}
                                   data-aos="fade-down"
                                   data-aos-duration="1500"
                                   data-aos-delay="1000"
                                   alt="logoApresentation"
                              />
                         </div>
                    </>
               )}

               {/* COMO FUNCIONA A TERAPIA ONLINE */}
               <section id="terapia-online">
                    <h2
                         data-aos="fade-up"
                         data-aos-offset="110"
                         className="m-50-bottom comecar"
                    >
                         Como começar a terapia online na{' '}
                         <span>Raízes Psicologia</span>
                    </h2>
                    <div className="passos desktop">
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="150"
                         >
                              <img src={passo1} alt="img-passo1" />
                              <p>Entre em contato por e-mail ou Whatsapp</p>
                         </div>
                         <div
                              className="passo2"
                              data-aos="fade-up"
                              data-aos-offset="150"
                         >
                              <img src={passo2} alt="img-passo2" />
                              <p>
                                   Escolha o dia e horário mais convenientes
                                   para você.
                              </p>
                         </div>
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="150"
                         >
                              <img src={passo3} alt="img-passo3" />
                              <p>
                                   Defina o método de pagamento mais confortável
                                   para você.
                              </p>
                         </div>
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="150"
                         >
                              <img src={passo4} alt="img-passo4" />
                              <p>
                                   Em um lugar tranquilo, faça sua sessão do
                                   computador ou celular
                              </p>
                         </div>
                    </div>
                    <div className="passos mobile">
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="300"
                         >
                              <img src={passo1} alt="img-passo1" />
                              <p>Entre em contato por e-mail ou Whatsapp</p>
                         </div>
                         <div
                              className="passo2"
                              data-aos="fade-up"
                              data-aos-offset="310"
                         >
                              <img src={passo2} alt="img-passo2" />
                              <p>
                                   Escolha o dia e horário mais convenientes
                                   para você.
                              </p>
                         </div>
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="320"
                         >
                              <img src={passo3} alt="img-passo3" />
                              <p>
                                   Defina o método de pagamento mais confortável
                                   para você.
                              </p>
                         </div>
                         <div
                              className="passo1"
                              data-aos="fade-up"
                              data-aos-offset="330"
                         >
                              <img src={passo4} alt="img-passo4" />
                              <p>
                                   Em um lugar tranquilo, faça sua sessão do
                                   computador ou celular
                              </p>
                         </div>
                    </div>
                    <h2 className="conheca-espaco m-50">
                         Também temos terapia presencial,
                         <Link to="/estrutura">
                              <span>conheça nosso espaço</span>
                         </Link>
                    </h2>
               </section>

               {/* FIM COMO FUNCIONA A TERAPIA ONLINE */}

               {/* BENEFICIOS TERAPIA ONLINE */}

               <section id="beneficios-terapia-online">
                    <h2
                         data-aos="fade-zoom-in"
                         data-aos-anchor-placement="center-bottom"
                         data-aos-delay="500"
                    >
                         <span>Beneficios</span> da terapia online
                    </h2>
                    <div className="containers-beneficio">
                         <div
                              className="ben-1"
                              data-aos="fade-right"
                              data-aos-duration="1200"
                         >
                              <h4
                                   className="title"
                                   data-aos="zoom-in-right"
                                   data-aos-delay="1000"
                                   data-aos-anchor-placement="bottom-bottom"
                              >
                                   Maior sigilo
                              </h4>
                              <p className="descricao">
                                   As pessoas que realizam terapia online se
                                   sentem mais seguras com o Psicólogo, pois
                                   existe um forte senso de anonimato.
                              </p>
                         </div>
                         <div
                              className="ben-2"
                              data-aos="fade-down"
                              data-aos-offset="100"
                              data-aos-duration="1500"
                         >
                              <h4
                                   className="title"
                                   data-aos="zoom-in-right"
                                   data-aos-delay="1000"
                                   data-aos-anchor-placement="bottom-bottom"
                              >
                                   Conforto
                              </h4>
                              <p className="descricao">
                                   O conforto estimula a desinibição e
                                   principalmente a privacidade de não ter que
                                   ir a ambientes públicos.
                              </p>
                         </div>
                         <div
                              className="ben-3"
                              data-aos="fade-left"
                              data-aos-offset="100"
                              data-aos-duration="1800"
                         >
                              <h4
                                   className="title"
                                   data-aos="zoom-in-right"
                                   data-aos-delay="1000"
                                   data-aos-anchor-placement="bottom-bottom"
                              >
                                   Maior presença
                              </h4>
                              <p className="descricao">
                                   Como paciente, você pode enviar uma mensagem
                                   para mim com alguma dúvida ou questão e mesmo
                                   fora do horário da sessão farei questão de
                                   responder o mais rápido possível
                              </p>
                         </div>
                    </div>
               </section>

               {/* FIM BENEFICIOS TERAPIA ONLINE */}

               {/* PREVIEW BLOG DESKTOP */}
               <section
                    className="preview-blog"
                    data-aos="fade-up"
                    data-aos-offset="120"
               >
                    <div className="retangle-preview">
                         <div>
                              <h2>Raízes Blog: Fique por Dentro</h2>
                              <h6>Scroll Horizontal</h6>
                         </div>

                         <Link to="/blog" className="btn btn-saiba-mais">
                              Visiste nosso blog
                         </Link>
                    </div>
                    {Center()}
                    {CenterMobile()}
               </section>
               {/* FIM PREVIEW BLOG */}
               {/* Depoimentos */}
               <section id="section-depoimentos">
                    <div className="depoimentos">
                         <h3>Depoimentos</h3>
                         {depoimentos()}
                    </div>
               </section>
               {/* Depoimentos*/}
               {/* EFICACIA */}
               <section id="eficacia">
                    <img src={coracao} width="100px" alt="icon-coracao" />
                    <p>
                         Consulta por <span>videoconferência</span> é tão eficaz
                         quanto a presencial.
                    </p>
                    <Link to={'/eficacia'} className="btn descobrir">
                         Descobrir
                    </Link>
               </section>
               {/* FIM EFICACIA */}

               {/* FOOTER */}

               <Footer />

               {/* <Footer /> */}
               {/* Informação importante */}
               <section id="informationImportant">
                    <div className="container-warning">
                         <img src={warning} width={50} alt="imgWarning" />
                    </div>
                    <div className="container-information">
                         Atenção: Este site não oferece tratamento ou
                         aconselhamento imediato para pessoas em crise suicida.
                         Em caso de crise, ligue para <span>188 (CVV)</span> ou
                         acesse o site <span>www.cvv.org.br.</span> Em caso de
                         emergência, procure atendimento em um hospital mais
                         próximo.
                    </div>
               </section>
               {/* Fim informaçao importante */}
          </main>
     );
};

export default Home;
