import React from 'react';
import { Link } from 'react-router-dom';
import { default as pdf } from '../../assets/ajuda/codigo_etica.pdf';
import { default as cfp } from '../../assets/ajuda/cfp.pdf';
import './footer.css';

const footer = () => {
     return (
          <div id="footer">
               <div className="footer-div-superior">
                    <div className="resp-tec">
                         <h4>Responsabilidade Técnica</h4>

                         <div>
                              <h5>Letícia Medeiros</h5>
                              <h5>CRP 04/26938</h5>
                         </div>
                         <p>
                              Psicóloga com formação em Terapia Cognitivo
                              Comportamental / Pós-graduação em Psicologia e
                              Desenvolvimento Humano
                         </p>
                    </div>
                    <div className="raizes-psicologia">
                         <h4>Raízes Psicologia</h4>
                         <ul>
                              <li>
                                   <Link to="/home">Home</Link>
                              </li>
                              <li>
                                   <Link to="/fale-conosco">Fale conosco</Link>
                              </li>
                              <li>
                                   <Link to="/estrutura">Estrutura</Link>
                              </li>
                         </ul>
                    </div>
                    <div className="explore">
                         <h4>Explore</h4>
                         <ul>
                              <li>
                                   <Link to="/blog">Blog</Link>
                              </li>
                         </ul>
                    </div>

                    <div className="rede-sociais">
                         <div>
                              {/* <a href="www.google.com.br">
                                   <svg
                                        width="30px"
                                        height="30px"
                                        preserveAspectRatio="xMidYMin meet"
                                        viewBox="0 0 30 30"
                                        fill="#cccc"
                                   >
                                        <path
                                             // style="fill:#ccc"

                                             d="M26,0H4C1.8,0,0,1.8,0,4V26c0,2.2,1.8,4,4,4h10.8l0-10.7h-2.8c-0.4,0-0.7-0.3-0.7-0.7l0-3.5
                                c0-0.4,0.3-0.7,0.7-0.7h2.8v-3.3c0-3.9,2.4-6,5.8-6h2.8c0.4,0,0.7,0.3,0.7,0.7v2.9c0,0.4-0.3,0.7-0.7,0.7l-1.7,0
                                c-1.9,0-2.2,0.9-2.2,2.2v2.9h4.1c0.4,0,0.7,0.3,0.7,0.7l-0.4,3.5c0,0.3-0.3,0.6-0.7,0.6h-3.7l0,10.7H26c2.2,0,4-1.8,4-4V4
                                C30,1.8,28.2,0,26,0z"
                                        ></path>
                                   </svg>
                              </a> */}
                              <a href= 'https://api.whatsapp.com/send?phone=+5521998234720&text=Olá, tudo bem? Vim pelo site Raízes psicologia'>
                                   <svg
                                        width="30px"
                                        height="30px"
                                        preserveAspectRatio="xMidYMin meet"
                                        viewBox="0 0 30 30"
                                        fill="#cccc"
                                   >
                                        <g>
                                             <path
                                                  d="M21.1,0H8.9C4,0,0,4,0,8.9v12.1C0,26,4,30,8.9,30h12.1c4.9,0,8.9-4,8.9-8.9V8.9
                                    C30,4,26,0,21.1,0z M27,21.1c0,3.3-2.7,5.9-5.9,5.9H8.9C5.7,27,3,24.3,3,21.1V8.9C3,5.7,5.7,3,8.9,3h12.1C24.3,3,27,5.7,27,8.9
                                    L27,21.1L27,21.1z"
                                             ></path>
                                             <path
                                                  d="M15,7.2c-4.3,0-7.8,3.5-7.8,7.8s3.5,7.8,7.8,7.8s7.8-3.5,7.8-7.8S19.3,7.2,15,7.2z M15,19.7
                                c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7C19.7,17.6,17.6,19.7,15,19.7z"
                                             ></path>
                                             <circle
                                                  cx="22.8"
                                                  cy="7.3"
                                                  r="1.9"
                                             ></circle>
                                        </g>
                                   </svg>
                              </a>
                         </div>
                    </div>
               </div>
               <div className="divisao"></div>
               <div className="footer-div-inferior">
                    <div className="assuntos">
                         <h4>Assuntos mais lidos</h4>

                         <div>
                              <ul>
                                   <li>
                                        <Link to="/blog/saude/o-que-e-depressao">
                                             Depressão
                                        </Link>
                                   </li>
                                   <li>
                                        <Link to="/blog/transtornos/ansiedade">
                                             Ansiedade
                                        </Link>
                                   </li>
                                   <li>
                                        <Link to="/blog/saude/como-melhorar-a-autoestima">
                                             Autoestima
                                        </Link>
                                   </li>
                                   <li>
                                        <Link to="/blog/empresas/diversidade-nas-empresas">
                                             Diversidade nas empresas
                                        </Link>
                                   </li>
                                   <li>
                                        <Link to="/blog/saude/como-controlar-os-ciumes">
                                             Ciúmes
                                        </Link>
                                   </li>
                              </ul>
                         </div>
                    </div>
                    <div className="ajuda">
                         <h4>Ajuda</h4>
                         <ul>
                              <li>
                                   <Link to={pdf} target="_blank">
                                        Código de ética
                                   </Link>
                              </li>
                              {/* <li><Link to={cfp}target="_blank" >Resolução do CFP</Link></li> */}
                         </ul>
                    </div>
               </div>
               <p className="credito">
                    © 2021 Raízes Psicologia,{' '}
                    <a
                         target="_blank"
                         rel="noreferrer"
                         href="https://api.whatsapp.com/send?phone=+5521986564067&amp;text=Olá, tudo bem? Vim pelo site Raízes Psicologia"
                    >
                         desenvolvido por Bruno dos Santos
                    </a>
                    .
               </p>
          </div>
     );
};

export default footer;
